import React, { useState } from "react";
import {Button, Col, Input, Modal, Row} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import AccueilPubPage from "./pages/accueil-page";
import DashboardPubPage from "./pages/dashboard-page";
import PubLogementModalPage from "./campagne";
import CGUModalPage from "./cgu";

function PubModalPage({ status, handleCancel, user }) {
    const [sectionModalPub, setSectionModalPub] = useState('CGUModalPage');
    const [sectionModalPubWidth, setSectionModalPubWidth] = useState(950);
    const [sectionModalPubTop, setSectionModalPubTop] = useState(260);
    const [sectionModalClosable, setSectionModalClosable] = useState(false);
    const [sectionModalClass, setSectionModalClass] = useState("pub-cgu-page");
    const onCancel = () => {
        handleCancel();
    };
    return (
        <>
            <Modal
                destroyOnClose
                style={{top: sectionModalPubTop /*65*/}}
                maskClosable={sectionModalClosable}
                open={status} footer={null} closable={sectionModalClosable} width={sectionModalPubWidth /*1800*/} onCancel={onCancel} className={"pub-page pub-page-special "+sectionModalClass} >


                {sectionModalPub === "CGUModalPage" && <div className="pubCGU"><CGUModalPage itemSelected={setSectionModalPub} user={user} handleCancel={handleCancel} /> </div>}
                {sectionModalPub === "AccueilPubPage" && <div className="pub"><AccueilPubPage itemSelected={setSectionModalPub} /> </div>}
                {sectionModalPub === "DashboardPubPage" && <div className="pub"><DashboardPubPage itemSelected={setSectionModalPub}/></div>}
                {sectionModalPub === "PubLogementModalPage" && <div className="pub2"><PubLogementModalPage itemSelected={setSectionModalPub}/></div>}

            </Modal>
        </>
    );
}

export default PubModalPage;