import React, { useState } from 'react';
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import ProfilPartPage from "./profil";
import InformationPartPage from "./informations";
import FacturesPartPage from "./factures";
import AttestationPartPage from "./attestations";
import CloturePartPage from "./cloture";
import "./styles/style.scss"
import DelaiPartPage from "./delai";


function ProfilPage() {
    const { user } = useSelector((state) => state.auth);
    const [itemSelected, setItemSelected] = useState("Informations de facturation");
    const handleSelect = async (value) => setItemSelected(value);

    return (
        <div className="profil-page-padding">
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Paramètres</h1>
                </div>
            </div>

            <div className='page-content'>
                <Row>
                    <Col lg={6}>
                        <div className="profilSidebar">
                            <div className="inContent">
                                <ul>
                                    <li className={itemSelected === "Profil" && "active"} onClick={() => handleSelect("Profil")}>Profil</li>
                                    <li className={itemSelected === "Informations de facturation" && "active"} onClick={() => handleSelect("Informations de facturation")}>Informations de facturation</li>
                                    <li className={itemSelected === "Délai de paiement" && "active"} onClick={() => handleSelect("Délai de paiement")}>Délai de paiement</li>
                                    <li className={itemSelected === "Factures" && "active"} onClick={() => handleSelect("Factures")}>Factures</li>
                                    {user.profil === "particulier" ? <li className={itemSelected === "Attestations crédit d’impôt" && "active"} onClick={() => handleSelect("Attestations crédit d’impôt")}>Attestations crédit d’impôt</li> : <li className="disabled">Attestations crédit d’impôt</li>}
                                    <li className="disabled">Équipe</li>
                                    <li className="disabled">Notifications</li>
                                    <li className={itemSelected === "Clôture du compte" && "active"} onClick={() => handleSelect("Clôture du compte")}>Clôture du compte</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={18}>
                        <div className="profilContent">
                            <div className="inCOntent">
                                {
                                    itemSelected === "Profil" ?
                                        <ProfilPartPage /> :
                                        itemSelected === "Informations de facturation" ?
                                            <InformationPartPage /> :
                                            itemSelected === "Factures" ?
                                                <FacturesPartPage /> :
                                                itemSelected === "Attestations crédit d’impôt" ?
                                                    <AttestationPartPage /> :
                                                itemSelected === "Délai de paiement" ?
                                                    <DelaiPartPage /> : <CloturePartPage />

                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ProfilPage;
