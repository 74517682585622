import React, { useState } from "react";
import {Badge, Button, Carousel, Col, Input, Modal, Progress, Row, Select} from "antd";


const Slide3CGUModalPage = ({itemSelected, user}) => {
    const carouselRef = React.createRef();

    console.log(user);
    return (
        <div className='Slide1CGUModalPage'>
            <div className="headTitle">
                <img src="/assets/img/logo.png" alt=""/>
                <div className="h1">Conditions contractuelles</div>
            </div>
            <div className="content">
                <Row>
                    <Col lg={24}>
                        <div className="condition">
                            <div className="content-condition scroll-none">
                                <p>Nota Bene&nbsp;:</p>
                                <ul>
                                    <li>Tout contrat d&eacute;j&agrave; sign&eacute; avec notre &eacute;quipe commerciale pr&eacute;vaut sur les pr&eacute;sentes conditions</li>
                                    <li>Vos coordonn&eacute;es compl&egrave;tes (vous ou l&rsquo;entit&eacute; que vous repr&eacute;sentez) seront automatiquement mises &agrave; jour au fil du temps dans la version PDF du contrat qui sera sauvegard&eacute;e</li>
                                </ul>
                                <p>Entre les soussign&eacute;s&nbsp;:</p>
                                <div><strong>Le prestataire</strong></div>
                                <div>La soci&eacute;t&eacute;&nbsp;: NETOREV SYSTEMS</div>
                                <div>Si&egrave;ge social&nbsp;: 26 Avenue de Thi&egrave;s, Caen</div>
                                <div>Repr&eacute;sent&eacute;e par&nbsp;: Brice W. Ngouo</div>
                                <div>Ci-apr&egrave;s d&eacute;nomm&eacute;e&nbsp;: Kliner</div>
                                <p>Et</p>
                                <div><strong>Le client</strong></div>
                                <div>VOUS: {user["email"] ?? user["phone"]}</div>
                                <p>Il a &eacute;t&eacute; convenu ce qui suit&nbsp;:</p>
                                <p><strong>OBJET</strong></p>
                                <p><strong>Article 1. </strong>Le pr&eacute;sent contrat a pour objet de d&eacute;terminer les conditions dans lesquelles Kliner assurera l&rsquo;entretien des logements mis en location saisonni&egrave;re par le loueur, pour lui permettre d&rsquo;offrir d&rsquo;excellentes conditions de s&eacute;jour aux voyageurs qu&rsquo;il re&ccedil;oit.</p>
                                <p><strong>MISSION DE KLINER</strong></p>
                                <p><strong>Article 2. </strong>Kliner assurera les prestations suivantes dans les logements qui lui seront confi&eacute;s par le loueur&nbsp;:</p>
                                <ul>
                                    <li>Tous types de travaux de m&eacute;nage (remise en l&rsquo;&eacute;tat, v&eacute;rification de l&rsquo;&eacute;tat de propret&eacute; des biens entre deux locations, remplacement du linge, v&eacute;rification de la vaisselle&hellip;)</li>
                                </ul>
                                <ul>
                                    <li>Tous types de missions compl&eacute;mentaires &agrave; l&rsquo;entretien d&rsquo;une location saisonni&egrave;re (fourniture de produits d&rsquo;accueil &agrave; la demande, v&eacute;rification et suivi des cl&eacute;s, suivi des d&eacute;gradations, autres petits travaux de bricolage et d&rsquo;assistance)</li>
                                </ul>
                                <p><strong>GARANTIES</strong></p>
                                <p><strong>Article 3.</strong> Si au terme d&rsquo;une prestation le loueur remonte une insatisfaction corrobor&eacute;e par un avis n&eacute;gatif du voyageur, Kliner d&eacute;dommage le loueur selon la grille suivante&nbsp;:</p>
                                <ul>
                                    <li>30% du total HT de la prestation de m&eacute;nage est rembours&eacute; si note voyageur &eacute;quivaut &agrave; 3 sur 5 ou alors 6 sur 10</li>
                                    <li>50% du total HT de la prestation est rembours&eacute; si note voyageur &eacute;quivaut &agrave; 1 ou 2 sur 5 ou alors moins de 5 sur 10.</li>
                                </ul>
                                <p><strong>Article 4.</strong> En cas d&rsquo;annulation d&rsquo;une r&eacute;servation pour le loueur &agrave; cause d&rsquo;un manquement grave li&eacute; &agrave; une prestation de m&eacute;nage confi&eacute;e &agrave; Kliner, Kliner rembourse la totalit&eacute; du montant de la r&eacute;servation, dans la limite de 600&euro; par r&eacute;servation annul&eacute;e.</p>
                                <p><strong>OBLIGATIONS DE KLINER</strong></p>
                                <p><strong>Article 5.</strong> Kliner s&rsquo;engage &agrave; apporter tous les soins et toutes les diligences n&eacute;cessaires &agrave; la r&eacute;alisation des missions en suivant scrupuleusement les instructions qui lui seront donn&eacute;es &agrave; cet effet par le Client, de fa&ccedil;on &agrave; satisfaire l&rsquo;utilisateur final du logement, le voyageur. De m&ecirc;me, en tant qu&rsquo;entreprise ind&eacute;pendante, Kliner assumera seule toutes les charges li&eacute;es &agrave; l&rsquo;exploitation de ses activit&eacute;s pour r&eacute;pondre aux besoins du Client faisant l&rsquo;objet du pr&eacute;sent contrat.</p>
                                <p><strong>Article 6.</strong> Kliner peut recourir en sous-traitance, aux services de soci&eacute;t&eacute;s de nettoyage ind&eacute;pendantes, pour l&rsquo;ex&eacute;cution de tout ou partie des missions faisant l&rsquo;objet de ce Contrat.</p>
                                <p>Dans cette hypoth&egrave;se, les missions pr&eacute;vues au pr&eacute;sent Contrat seront ex&eacute;cut&eacute;es sous le pilotage exclusif et la responsabilit&eacute; de Kliner, par une ou plusieurs soci&eacute;t&eacute;s de nettoyage membres du r&eacute;seau de partenaires de Kliner, ce que vous reconnaissez et acceptez.</p>
                                <p><strong>OBLIGATIONS DU LOUEUR</strong></p>
                                <p><strong>Article 7.</strong> Le Loueur s&rsquo;engage &agrave; fournir &agrave; Kliner toutes les informations et documentations, ainsi que toute l&rsquo;assistance raisonnablement n&eacute;cessaires pour permettre &agrave; Kliner d&rsquo;ex&eacute;cuter dans de bonnes conditions les missions objet du pr&eacute;sent Contrat.</p>
                                <p>En outre, le Loueur doit veiller &agrave; fournir des informations descriptives exactes de ses logements, de fa&ccedil;on &agrave; ne pas induire Kliner en erreur dans l&rsquo;estimation de la dur&eacute;e de prestation n&eacute;cessaire pour r&eacute;aliser ses missions. Si Kliner constate un &eacute;cart entre les d&eacute;clarations du Loueur et les constations faites sur place lors des prestations, cela d&eacute;chargera instamment Kliner de son obligation de r&eacute;sultat et le Loueur ne pourra plus pr&eacute;tendre aux garanties cit&eacute;es en article 3.</p>
                                <p>Le Loueur s&rsquo;engage &eacute;galement &agrave; fournir &agrave; Kliner un jeu de cl&eacute;s complet de chaque bien lou&eacute; objet du pr&eacute;sent contrat afin que Kliner puisse ex&eacute;cuter ses missions. &Agrave; d&eacute;faut, le Loueur s&rsquo;engage &agrave; fournir toutes les informations n&eacute;cessaires pour permettre &agrave; Kliner d&rsquo;acc&eacute;der aux logements sur lesquels des missions lui sont confi&eacute;es.</p>
                                <p><strong>Article 8. </strong>Le Loueur s&rsquo;interdit express&eacute;ment de solliciter en vue d&rsquo;une embauche ou d&rsquo;embaucher directement ou indirectement tout membre du personnel d&rsquo;un Prestataire Sous-traitant auquel il a eu acc&egrave;s gr&acirc;ce &agrave; Kliner, dans le cadre de l&rsquo;ex&eacute;cution de ce contrat. De m&ecirc;me, le Loueur s&rsquo;engage &agrave; ne pas d&eacute;marcher les Prestataires Sous-traitants partenaires de Kliner pour conclure tout contrat de prestation de services avec ces derniers m&ecirc;me apr&egrave;s la fin &eacute;ventuelle du pr&eacute;sent Contrat et ce, pour une dur&eacute;e de deux (2) ans. Le Loueur se porte fort de l&rsquo;application de cette interdiction aux autres soci&eacute;t&eacute;s du Groupe auquel lui (le repr&eacute;sentant) ou son entit&eacute; peut appartenir, le cas &eacute;ch&eacute;ant. En cas d&rsquo;infraction &agrave; la pr&eacute;sente interdiction, le Loueur sera tenu de payer imm&eacute;diatement &agrave; Kliner, &agrave; titre de clause p&eacute;nale, une indemnit&eacute; forfaitaire hors taxes d&rsquo;un montant de seize mille euros (16 000 &euro;).</p>
                                <p><strong>DUR&Eacute;E, RENOUVELLEMENT, ARR&Ecirc;T DU CONTRAT</strong></p>
                                <p><strong>Article 9.</strong>  Le pr&eacute;sent Contrat, qui commence à courir à compter de sa date de signature par le Loueur, est conclu pour une durée ind&eacute;termin&eacute;e. Chaque partie pourra y mettre fin à tout moment sous r&eacute;serve du respect d’un pr&eacute;avis écrit de 30 jours,
                                    envoy&eacute; par mail (support@kliner.com) ou courrier avec accus&eacute; de r&eacute;ception (Netorev Systems / R&eacute;siliation – 26 Avenue de Thi&egrave;s, 14000 Caen).
                                </p>

                                <p>Si en l&rsquo;absence de pr&eacute;avis de fin de Contrat le Loueur demande &agrave; r&eacute;cup&eacute;rer toutes les cl&eacute;s des logements confi&eacute;es &agrave; Kliner, cela &eacute;quivaudrait de fait &agrave; une rupture abusive du Contrat, auquel cas des indemnit&eacute;s de rupture abusive de Contrat seront factur&eacute;es au Loueur, pour un montant &eacute;quivalant &agrave; la moyenne hors taxe des factures mensuelles des prestations pay&eacute;es par le Loueur &agrave; Kliner au cours des 12 derniers mois.</p>
                                <p><strong>PRIX</strong></p>
                                <p><strong>Article 10.</strong> La r&eacute;mun&eacute;ration de Kliner pour l&rsquo;ex&eacute;cution de ses services est fix&eacute;e entre les Parties de fa&ccedil;on sp&eacute;cifique pour chaque mission concern&eacute;e. En effet Kliner indique au Loueur dans ses interfaces digitales, le tarif de chaque prestation avant qu&rsquo;il ne puisse la commander. Toute prestation command&eacute;e vaut acceptation du tarif propos&eacute;.</p>
                                <p><strong>Article 11.</strong> Le Loueur ne sera factur&eacute; que pour les prestations r&eacute;alis&eacute;es. Si toutefois plus de quatre (4) mois apr&egrave;s la conclusion du Contrat&nbsp;aucune prestation n&rsquo;est command&eacute;e et que dans ce laps de temps Kliner a engag&eacute; des actions commerciales pour r&eacute;pondre aux besoins annonc&eacute;s du Client (par exemple&nbsp;: recruter des &eacute;quipes de m&eacute;nage, acheter du mat&eacute;riel de nettoyage sp&eacute;cifique&hellip;), alors Kliner sera en droit de facturer au Loueur un forfait de &laquo;&nbsp;Mise en place de service&nbsp;&raquo; d&rsquo;un montant de 600&euro; HT, sans que le Loueur ne puisse le contester.</p>
                                <p><strong>D&Eacute;LAIS DE PAIEMENT</strong></p>
                                <p><strong>Article 12.</strong> Les missions objet du pr&eacute;sent Contrat sont par d&eacute;faut factur&eacute;es et d&eacute;bit&eacute;es au Loueur &agrave; la fin de chaque prestation (sur le moyen de paiement enregistr&eacute; sur la plateforme Kliner par le Loueur). Le Loueur peut &agrave; tout moment demander &agrave; &ecirc;tre factur&eacute; et d&eacute;bit&eacute; au premier des deux termes &eacute;chus&nbsp;: &agrave; la fin du mois ou d&egrave;s l&rsquo;atteinte d&rsquo;un seuil budg&eacute;taire de 500&euro; (si Entreprise) ou 200&euro; (si Particulier).</p>
                                <p>D&egrave;s lors que le compte Kliner du Loueur comptabilise cinq (5) factures d&ucirc;ment r&eacute;gl&eacute;es, le Loueur peut librement choisir entre trois (3) options de mode et d&eacute;lai de paiement suppl&eacute;mentaires&nbsp;:</p>
                                <ul>
                                    <li>Paiement manuel &agrave; J+7&nbsp;: le Loueur re&ccedil;oit sa facture et la r&egrave;gle lui-m&ecirc;me par CB, SEPA ou virement bancaire (pas de d&eacute;bit automatique du moyen de paiement), dans un d&eacute;lai de 7 jours apr&egrave;s la date d&rsquo;&eacute;mission.</li>
                                </ul>
                                <ul>
                                    <li>Paiement manuel &agrave; J+15&nbsp;: le Loueur re&ccedil;oit sa facture et la r&egrave;gle lui-m&ecirc;me par CB, SEPA ou virement bancaire (pas de d&eacute;bit automatique du moyen de paiement), dans un d&eacute;lai de 15 jours apr&egrave;s la date d&rsquo;&eacute;mission. Entra&icirc;ne une majoration de 1% du total de la facture.</li>
                                </ul>
                                <ul>
                                    <li>Paiement manuel &agrave; J+20&nbsp;: le Loueur re&ccedil;oit sa facture et la r&egrave;gle lui-m&ecirc;me par CB, SEPA ou virement bancaire (pas de d&eacute;bit automatique du moyen de paiement), dans un d&eacute;lai de 20 jours apr&egrave;s la date d&rsquo;&eacute;mission. Entra&icirc;ne une majoration de 2% du total de la facture.</li>
                                </ul>
                                <p><strong>Article 13.</strong> D&egrave;s lors qu&rsquo;une facture &eacute;mise par Kliner &agrave; destination du Loueur n&rsquo;est pas r&eacute;gl&eacute;e 5 jours apr&egrave;s la date d&rsquo;&eacute;ch&eacute;ance pr&eacute;vue, Kliner suspend imm&eacute;diatement tous ses services et passe en recouvrement les sommes dues. Dans cette hypoth&egrave;se, le Loueur lib&egrave;re Kliner de toute responsabilit&eacute; sur des cons&eacute;quences &eacute;ventuelles pour l&rsquo;activit&eacute; du Loueur, de la cessation de l&rsquo;ex&eacute;cution des prestations.</p>
                                <p><strong>CLAUSE ATTRIBUTIVE DE COMP&Eacute;TENCE</strong></p>
                                <p>Le pr&eacute;sent Contrat est r&eacute;gi par la loi fran&ccedil;aise. Pour toutes les contestations auxquelles peut donner lieu le pr&eacute;sent Contrat, les parties donnent comp&eacute;tence exclusive au Tribunal de Commerce de Grasse, sans que Kliner ne puisse &ecirc;tre cit&eacute; devant un autre Tribunal, m&ecirc;me en cas de pluralit&eacute; de d&eacute;fendeurs.</p>
                                <p><strong>ANNEXES</strong></p>
                                <p>De convention expresse entre les Parties, tous les documents annex&eacute;s au Contrat en font partie int&eacute;grante et sont consid&eacute;r&eacute;s comme formant un ensemble indivisible et indissociable.</p>
                                <p>Sont ainsi annex&eacute;s au Contrat les documents suivants&nbsp;:</p>
                                <ul>
                                    <li><strong>Conditions g&eacute;n&eacute;rales d&rsquo;utilisation de la plateforme Kliner &amp; Politique de confidentialit&eacute;</strong>=&gt; accessible ici&nbsp;: <a href="https://www.kliner.com/mentions-legales-confidentialite/">https://www.kliner.com/mentions-legales-confidentialite/</a></li>
                                </ul>
                                <ul>
                                    <li><strong>Diverses Politiques de Kliner pour la gestion des cas courants li&eacute;s &agrave; l&rsquo;ex&eacute;cution des missions objet du pr&eacute;sent Contrat</strong> (Politique en cas de perte de cl&eacute;s, Politique li&eacute;e aux assurances, etc.) =&gt; accessible ici&nbsp;: <a href="https://help.kliner.com/fr/collections/9408589">https://help.kliner.com/fr/collections/9408589</a></li>
                                </ul>
                                <p><strong>SIGNATURE</strong></p>
                                <p>En acceptant les pr&eacute;sentes conditions (lorsque vous cliquez sur &lsquo;Accepter&rsquo;), vous consentez &ecirc;tre li&eacute;(e) par les pr&eacute;sentes Conditions qui ent&eacute;rinent une relation contractuelle entre vous-m&ecirc;me et Kliner. Votre acceptation vaut donc pleine signature du contrat de prestation. Si vous n&rsquo;acceptez pas les pr&eacute;sentes Conditions, vous ne pourrez pas acc&eacute;der aux Services ni les utiliser.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Slide3CGUModalPage;