import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../pages/auth/services/auth-slice";
import { authApi } from "../pages/auth/services/auth-api";
import { dashboardApi } from "../pages/dashboard/services/dashboard-api";
import { logementApi } from "../pages/logements/services/logement-api";
import { productApi } from "../pages/logements/services/product-api";
import { instructionApi } from "../pages/logements/services/instruction-api";
import logementSlice from "../pages/logements/services/logement-slice";
import { profilApi } from "../pages/profil/services/profil-api";
import { planningApi } from "../pages/planning/services/planning-api";
import planningSlice from "../pages/planning/services/planning-slice";
import { equipeApi } from "../pages/equipes/services/equipe-api";
import dashboardSlice from "../pages/dashboard/services/dashboard-slice";
import {logApi} from "../pages/planning/services/log-api";
import {mediaprestationApi} from "../pages/logistique/services/mediaprestation-api";
import {controleFinPrestaApi} from "../pages/planning/services/control-qualite-api";
import {aminitieApi, lingeApi} from "../pages/planning/services/aminitie-linge-api";


export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [logementApi.reducerPath]: logementApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [instructionApi.reducerPath]: instructionApi.reducer,
    [planningApi.reducerPath]: planningApi.reducer,
    [profilApi.reducerPath]: profilApi.reducer,
    [equipeApi.reducerPath]: equipeApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [controleFinPrestaApi.reducerPath]: controleFinPrestaApi.reducer,
    [mediaprestationApi.reducerPath]: mediaprestationApi.reducer,
    [aminitieApi.reducerPath]: aminitieApi.reducer,
    [lingeApi.reducerPath]: lingeApi.reducer,
    auth: authSlice,
    logement: logementSlice,
    planning: planningSlice,
    dashboard: dashboardSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      dashboardApi.middleware,
      logementApi.middleware,
      productApi.middleware,
      instructionApi.middleware,
      planningApi.middleware,
      profilApi.middleware,
      logApi.middleware,
      mediaprestationApi.middleware,
      controleFinPrestaApi.middleware,
      aminitieApi.middleware,
      lingeApi.middleware,
      equipeApi.middleware
    ),
});
