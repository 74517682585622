import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Input, Divider, message, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { apiKey, extractAddress, loadAsyncScript, mapApiJs } from "../../../../utils/googlemap-utils";
import { useInstructionCreateMutation, useInstructionUpdateMutation, useLogementUpdateMutation } from "../../services/logement-api";
import ExempleAutomatisationModal from "./exemple-instruction-modal";
import { CloseCircleOutlined } from "@ant-design/icons";
import { API_ROOT } from "../../../../utils/global-var";
import axios from 'axios';
import * as logementActions from '../../services/logement-slice'


const { TextArea } = Input;


function AdresseView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [logementUpdate] = useLogementUpdateMutation();
    const [instructionUpdate] = useInstructionUpdateMutation();
    const [instructionCreate] = useInstructionCreateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const searchInput = useRef(null);
    const [address, setAddress] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const { user } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [countStep, setCountStep] = useState(0);
    const [description, setDescription] = useState('');
    const [elements, setElements] = useState([]);
    const [instructions, setInstructions] = useState([]);
    const [instructionsId, setInstructionsId] = useState([]);
    const [image, setImage] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        reinitialisationView();
    }, [logement?.id]);

    const reinitialisationView = () => {
        setCountStep(0);
        setInstructions([]);
        setInstructionsId([]);
        setElements([]);
        setImage([]);
        for (let i = 0; i < logement?.instructions.length; i++) {
            setInstructions(prevInstructions => {
                return [...prevInstructions, logement?.instructions[i].content]
            });
            setInstructionsId(prevInstructions => {
                return [...prevInstructions, logement?.instructions[i].id]
            });
            setImage(prevImg => {
                return [...prevImg, logement?.instructions[i].image]
            });
            setCountStep(i);
        }
        if (logement?.instructions.length === 0) {
            setCountStep(2);
        } else {
            setCountStep(1);
        }
    }

    /*useEffect(() => {
        setElements([]);
    }, [logement?.id]);*/

   /* useEffect(() => {
        setElements(elements);
    }, [image]);*/

    useEffect(() => {
    }, [image]);

    const initMapScript = () => {
        if (window.google) return Promise.resolve();
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    };

    const initAutocomplete = (searchInput) => {
        if (!searchInput) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput);
        autocomplete.setFields(["address_component", "geometry", "formatted_address", "name"]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            const { lat, lng } = place.geometry.location;
            setLatitude(lat());
            setLongitude(lng());
            setAddress(extractAddress(place));
        });
    };

    const handleAdressSaveFunction = () => {
        if (address) handleAdressSave();
    };

    useEffect(() => {
        initMapScript().then(() => initAutocomplete(searchInput.current));
    }, []);

    useEffect(handleAdressSaveFunction, [address]);

    const handleAdressSave = async () => {
        if (address) {
            const data = ({
                libelle: address.street.slice(address?.street.lastIndexOf(" ") + 1),
                adresse: address?.street_number + " " + address?.street,
                ville: address?.city,
                pays: address?.country,
                codepostal: address?.zip,
                tarif: "Tarif lissé",
                latitude: latitude,
                longitude: longitude,
                status: false,
                user: user.id,
                profil: user.profil,
            })
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: data }).unwrap()
                .then(() => {
                    dispatch(logementActions.setRefresh(true));
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                });
        }
    };


    const addInstruction = () => {
        setCountStep(countStep + 1);
        var localCounter = instructions.length === 0 ? countStep : instructions.length + countStep -1;
        const newElement =
            <div className={"blc instruction_list_"+localCounter} key={"instruction_list_"+localCounter}>
                <p style={{ paddingTop: "20px;" }} className="mintitle"> Étape {localCounter +1}</p>
                <div className="acontent">
                    <TextArea placeholder="Décrire la première étape pour accéder au logement" rows={8}
                        onBlur={(e) => handleInstruction(e.target.value, localCounter)}
                        onChange={(e) => handleChangeInstruction(e.target.value, localCounter)} />
                </div>

                <br />
                <input type="file" accept="image/*" id={"fileInput_" + localCounter} style={{ display: "none" }} onChange={(e) => handleImageChange(e, localCounter)} />
                {image[localCounter] && (
                    <div>
                        <div className="closeButton" onClick={() => handleButtonResetImage(localCounter)}><CloseCircleOutlined style={{ color: "white", fontSize: "20px" }} /></div>
                        <img src={image[localCounter]} alt="Preview"
                            style={{ width: '100%', maxHeight: '150px', borderRadius: '20px' }} />
                    </div>
                )}
                {!image[localCounter] && (
                    <div className="ajouter-photo" onClick={() => handleButtonClick(localCounter)}>
                        <span>
                            Ajouter une photo illustrative
                        </span>
                    </div>

                )}
            </div>;
        setElements([...elements, newElement]);
    }

    const handleChangeInstruction = (value, nb) => {
        setInstructions(prevInstructions => {
            if (nb > prevInstructions.length) {
                return [...prevInstructions, value];
            } else {
                return prevInstructions.map((instruction, index) => {
                    return index === nb ? value : instruction;
                });
            }
        });
    }

    const handleInstruction = (value, index) => {
        setInstructions(prevInstructions => {
            if (index > prevInstructions.length) {
                return [...prevInstructions, value];
            } else {
                return prevInstructions.map((instruction, indexed) => {
                    return indexed === index ? value : instruction;
                });
            }
        });

        if (index + 1 > instructions.length) {
            var data = {
                logement: logement.id,
                instructions: [
                    {
                        logement: logement.id,
                        content: value,
                    }
                ]
            };
            saveInstruction(data);
        } else {
            saveInstructionExist(instructionsId[index], instructions[index], image[index]);
        }
    };

    const saveInstruction = (data) => {
        setIsLoading(true);
        instructionCreate(data).unwrap()
            .then((value) => {
                setInstructionsId(prevInstructions => {
                    return [...prevInstructions, value[0].id];
                });
                setIsLoading(false)
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => {
                setIsLoading(false)
            });
    }
    const saveInstructionExist = (id, data, image) => {
        setIsLoading(true)
        instructionUpdate({ id: id, content: data, image: image }).unwrap()
            .then((value) => {
                setIsLoading(false)
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => {
                setIsLoading(false)
            });
    }

    const handleImageChange = (e, indexed) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setIsLoading(true);
            axios.post(`${API_ROOT}/api/v1/upload`, formData)
                .then(response => {
                    if (indexed < image.length) {
                        instructionUpdate({
                            id: instructionsId[indexed],
                            content: instructions[indexed],
                            image: response.data
                        }).unwrap()
                            .then((value) => {
                                 setIsLoading(false);
                                messageApi.open({
                                    type: 'success',
                                    content: 'Modifications enregistrées',
                                    className: 'message-success'
                                });
                            })
                            .catch(() => {
                                setIsLoading(false)
                            });
                    } else {
                        var data = {
                            logement: logement.id,
                            instructions: [
                                {
                                    logement: logement.id,
                                    content: " ",
                                    image: response.data
                                }
                            ]
                        };
                        instructionCreate(data).unwrap()
                            .then((value) => {
                               setIsLoading(false)
                                messageApi.open({
                                    type: 'success',
                                    content: 'Modifications enregistrées',
                                    className: 'message-success'
                                });
                            })
                            .catch(() => {
                                setIsLoading(false)
                            });
                    }
                    setImage(prevImages => {
                        if (indexed + 1 > prevImages.length) {
                            console.log(prevImages.length);
                            console.log(indexed+1);
                            return [...prevImages, response.data];
                        } else {
                            return prevImages.map((img, index) => {
                                return index === indexed ? response.data : img;
                            });
                        }
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleButtonResetImage = (indexed) => {
        setImage(prevImages => {
            return prevImages.map((img, index) => {
                return index === indexed ? null : img;
            });
        });
        instructionUpdate({ id: instructionsId[indexed], content: instructions[indexed], image: null }).unwrap()
            .then(() => {
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => {
            });
    };

    const handleButtonClick = (index) => {
        document.getElementById('fileInput_' + index).click();
    };


    const disabledAddress = () => {
        if (logement?.adresse === null || logement?.adresse === "" || logement?.adresse?.length <= 10) {
            return false
        } else {
            return true
        }
    };
    //console.log(image);
    return (
        <div className="adresse blc-right">
            {contextHolder}
            <div className="title">Accès</div>
            <Divider />
            <div className="subTitle">Adresse</div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col lg={14}>
                        <div className="blc-content">
                            <Row gutter={24}>
                                <Col lg={24}>
                                    <Row gutter={24}>
                                        <Col lg={12}>
                                            <p className="mintitle">Où se situe le logement ?</p>
                                            <span className="color-9AA1AB sans-pro-regular">Commencez la saisie ci-contre et sélectionnez la bonne adresse dans le menu déroulant</span>
                                        </Col>

                                        <Col lg={10}>
                                            <input type="text" disabled={disabledAddress()} className="ant-input" ref={searchInput} placeholder={logement?.adresse ? (`${logement?.adresse}, ${logement?.ville} ${logement?.pays}`) : "Saisir l’adresse"} />
                                        </Col>
                                    </Row>
                                    <br /><br />

                                    <Row gutter={24}>
                                        <Col lg={12}>
                                            <p className="mintitle">Comment accède-t-on au logement ?</p>
                                            <span className="color-9AA1AB sans-pro-regular">Donnez toutes les informations utiles qui faciliteront l’accès à la porte d’entrée du logement</span>
                                        </Col>

                                        <Col lg={12}>
                                            <div className="ajouter-instruction" onClick={() => setShowModal(true)}>
                                                <span>
                                                    Exemple d’instructions complètes
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br /><br />

                            <Row gutter={24} className="contentInstruction">
                                {(logement?.instructions.length > 0) && (
                                    <>
                                        {logement?.instructions.map((element, index) => (
                                            <Col lg={8} key={index}>
                                                <div className={"blc instruction_list_"+index} key={"instruction_list_"+index}>
                                                    <p style={{ paddingTop: "20px;" }} className="mintitle"> Étape {index + 1}</p>
                                                    <div className="acontent">
                                                        <TextArea key={element.id} value={instructions[index]}
                                                            placeholder="Décrire l'étape pour accéder au logement"
                                                            rows={8}
                                                            onBlur={(e) => handleInstruction(e.target.value, index)}
                                                            onChange={(e) => handleChangeInstruction(e.target.value, index)} />
                                                    </div>

                                                    <br />
                                                    <input type="file" accept="image/*" id={"fileInput_" + index} style={{ display: "none" }} onChange={(e) => handleImageChange(e, index)} />
                                                    {image[index] && (
                                                        <div>
                                                            <div className="closeButton" onClick={() => handleButtonResetImage(index)}><CloseCircleOutlined style={{ color: "white", fontSize: "20px" }} /></div>
                                                            <img src={API_ROOT + '/' + image[index]} alt="Preview"
                                                                style={{ width: '100%', maxHeight: '150px', borderRadius: '20px' }} />
                                                        </div>
                                                    )}
                                                    {!image[index] && (
                                                        <div className="ajouter-photo" onClick={() => handleButtonClick(index)}>
                                                            <span>
                                                                Ajouter une photo illustrative
                                                            </span>
                                                        </div>

                                                    )}
                                                </div>
                                            </Col>
                                        ))}
                                        {elements.map((element, index) => (
                                            <Col lg={8} key={"plein_" + index}>{element}</Col>
                                        ))}
                                    </>
                                )
                                }
                                {(logement?.instructions.length === 0) && (
                                    <>
                                        <Col lg={8}>
                                            <div className={"blc instruction_list_"+0}>
                                                <p style={{ paddingTop: "20px;" }} className="mintitle"> Étape 1</p>
                                                <div className="acontent">
                                                    <TextArea value={instructions[""]}
                                                        placeholder="Décrire la première étape pour accéder au logement"
                                                        rows={8}
                                                        onBlur={(e) => handleInstruction(e.target.value, 1, 0)} />
                                                </div>

                                                <br />
                                                <input type="file" accept="image/*" id="fileInput_0" style={{ display: "none" }} onChange={(e) => handleImageChange(e, 0)} />
                                                {image[0] && (
                                                    <div>
                                                        <div className="closeButton" onClick={() => handleButtonResetImage(0)}><CloseCircleOutlined style={{ color: "white", fontSize: "20px" }} /></div>
                                                        <img src={image[0]} alt="Preview"
                                                            style={{ width: '100%', maxHeight: '150px', borderRadius: '20px' }} />
                                                    </div>
                                                )}
                                                {!image[0] && (
                                                    <div className="ajouter-photo" onClick={() => handleButtonClick(0)}>
                                                        <span>
                                                            Ajouter une photo illustrative
                                                        </span>
                                                    </div>

                                                )}
                                            </div>
                                        </Col>
                                        {elements.map((element, index) => (
                                            <Col lg={8} key={"vide_" + index}>{element}</Col>
                                        ))}
                                    </>
                                )
                                }

                                <Col lg={8}>
                                    <div className="blc add-step" onClick={addInstruction}>
                                        <span>+ Ajouter une étape</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Spin>

            <ExempleAutomatisationModal
                status={showModal}
                handleCancel={() => setShowModal(false)}
            />
        </div>
    );
}

export default AdresseView;
