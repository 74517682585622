import React, { useState } from 'react';
import { Row, Col, Divider, Input, message, Spin, Popover } from "antd";
import { useCoutDureeUpdateMutation, useLogementUpdateMutation } from '../services/logement-api';
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from '../services/logement-slice';



function SurfaceView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [supLogement, setSupLogement] = useState(logement.superficie === null ? 0 : logement.superficie);
   // const [cumul, setCumul] = useState(logement.balconySurfaceArea);
    const [isLoading, setIsLoading] = useState(false);
    //const [estime, setEstime] = useState(logement.gardenSurfaceArea);
    const [logementUpdate] = useLogementUpdateMutation();
    const [typeLogement, setTypeLogement] = useState(logement?.type ?? "");
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    console.log(typeLogement);
    console.log(logement);

    const handleChange = (e, value) => {
        if (value === "logement") {
            setSupLogement(e);
        }
        /*if (value === "cumul") {
            setCumul(e);
        }
        if (value === "estime") {
            setEstime(e);
        }*/
    };

    const handleBlur = (e, value) => {
        if (value === "logement") {
            handleUpdate({ superficie: e })
        }
        /*if (value === "cumul") {
            handleUpdate({ balconySurfaceArea: e })
        }
        if (value === "estime") {
            handleUpdate({ gardenSurfaceArea: e })
        }*/
    };

    const handleUpdate = async (data) => {
        setIsLoading(true)
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => handleUpdateCout(data.type))
            .catch(() => { setIsLoading(false) });
    };

    /*const contentInfo = (
        <div className="infoLogement">
            <p>
                Si seule une partie du logement est mise <br />
                en location saisonnière, veuillez <br />
                soustraire de la superficie totale du <br />
                logement les espaces qui ne seront pas <br />
                à nettoyer pendant les prestations.
            </p>
        </div>
    );*/

    const handleUpdateCout = (data) => {
        coutDureeUpdate({
            logementId: logement.id,
            superficie: supLogement,
            superficieBalcon: 0,
            superficieJardin: 0,
            houseType: data ?? typeLogement,
            userId: user.id,
            type: "superficie"
        }).unwrap()
            .then(() => {
                setIsLoading(false)
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => { setIsLoading(false) });
    };


    return (
        <Spin spinning={isLoading}>
            <div className="surface-a-nettoyer blc-right">
                {contextHolder}
                <div className="title">Surfaces à nettoyer</div>
                <Divider />
                <div className="contentToScroll scroll-none">
                    <Row gutter={24}>
                        <Col lg={14}>
                            <div className="subTitle">Type de logement</div>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col lg={16}>
                            <div className="blc-content blc-content-no-text">
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <div className="surface-choice-content">
                                            <br/>
                                            <Row gutter={24}>
                                                <Col lg={10}>
                                                    <p className="mintitle">Indiquez le type de logement</p>
                                                    <span className="color-9AA1AB sans-pro-regular">Cliquez sur l’icône correspondante ci-contre</span>
                                                </Col>
                                                <Col lg={14} style={{display: "flex", justifyContent: "flex-end", paddingRight: "50px"}}>
                                                    <Row>
                                                        <Col>
                                                            <div className={(typeLogement === "apartment" && "surface-choice-bloc-choosen " )+ " surface-choice-bloc " + (typeLogement !== "apartment" && typeLogement !== null && typeLogement !== "" && " disabled-block ")}>
                                                                <a href="#" onClick={() => {
                                                                    if(typeLogement === null || typeLogement === "") {
                                                                        setTypeLogement("apartment");
                                                                        handleUpdate({type: "apartment"});
                                                                    }
                                                                }}>
                                                                    { typeLogement === "apartment" && <div className="checker">
                                                                        <img src="/assets/img/check-green.png" alt="checker"/>
                                                                    </div>
                                                                    }
                                                                    <div className="surface-choice">
                                                                        <img src="/assets/img/apartment.png" alt="appart"/>
                                                                    </div>
                                                                    <div className="libelleSurface">
                                                                        Appartement
                                                                    </div>
                                                                </a>

                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className={(typeLogement === "villa" && "surface-choice-bloc-choosen " )+ " surface-choice-bloc " + (typeLogement !== "villa" && typeLogement !== null && typeLogement !== "" && " disabled-block ")}>
                                                                <a href="#"  onClick={() => {
                                                                    if(typeLogement === null || typeLogement === "") {
                                                                        setTypeLogement("villa");
                                                                        handleUpdate({type : "villa"});
                                                                    }
                                                                }}>
                                                                    { typeLogement === "villa" && <div className="checker">
                                                                        <img src="/assets/img/check-green.png" alt="checker"/>
                                                                    </div>
                                                                    }
                                                                    <div className="surface-choice">
                                                                        <img src="/assets/img/villa.png" alt="appart"/>
                                                                    </div>
                                                                    <div className="libelleSurface">
                                                                        Villa
                                                                    </div>
                                                                </a>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <br/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {typeLogement === "apartment" && <>
                                <br/> <br/>
                                <div className="subTitle">Superficie (m2)</div>
                                <div className="blc-content blc-content-no-text">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="contour-plein-gris">
                                                <Row gutter={24}>
                                                    <Col lg={20}>
                                                        <p className="mintitle">Superficie de l’appartement (obligatoire) *
                                                            {/*<Popover placement="top" content={contentInfo}
                                                              overlayClassName="infoContentLogement">
                                                        <div className="info-logement">
                                                            <img src="/assets/img/info-circled.png" alt="info"/>
                                                        </div>
                                                    </Popover>*/}
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <div className="nombre">
                                                            <Input
                                                                disabled={logement?.superficie?.length > 0 ? true : false}
                                                                className="nombre-input"
                                                                value={supLogement === 0 ? "" : supLogement}
                                                                placeholder={supLogement === 0 ? "0" : ""}
                                                                prefix={<div className="valeur">m²</div>}
                                                                onChange={(e) => handleChange(e.target.value, "logement")}
                                                                onBlur={(e) => handleBlur(e.target.value, "logement")}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <br/> <br/>
                                <div className="subTitle">
                                    <img src="/assets/img/info-pop-up_colored.png" className="title-img" alt="info"/>
                                    Ajustement de durée</div>
                                <div className="blc-content surface-a-nettoyer-second-p sans-pro-light">
                                    <p>Nous utilisons principalement la superficie du logement pour déterminer la durée
                                        forfaitaire
                                        des prestations de ménage.
                                        Cette durée est importante pour construire le planning des aides ménagères. </p>
                                    <br/>
                                    <p>Si la durée définie par défaut vous semble trop éloignée du temps habituellement
                                        nécessaire
                                        pour faire une bonne
                                        prestation selon votre expérience, vous pouvez <a
                                            href="https://app.lemcal.com/@rzdrilic/30-minutes" target="_blank">contacter
                                            votre Gestionnaire de
                                            compte</a>, qui se chargera de modifier la
                                        durée selon votre demande.</p> <br/>
                                    <p>Toutefois veuillez noter que si vous demandez une réduction de la durée forfaitaire
                                        de ménage
                                        ou si vous refusez une
                                        demande d’augmentation de notre part, vous libérez Kliner de notre engagement à ne
                                        facturer
                                        à 100% que les prestations
                                        notées 5 étoiles. Il en va de même si nous constatons un écart entre la superficie
                                        déclarée
                                        ici et celle constatée sur place.</p>
                                </div>
                            </>}
                            {typeLogement === "villa" && <>
                                <br/> <br/>
                                <div className="subTitle">Superficie (m2)</div>
                                <div className="blc-content blc-content-no-text">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="contour-plein-gris">
                                                <Row gutter={24}>
                                                    <Col lg={20}>
                                                        <p className="mintitle">Superficie de la villa (obligatoire) *
                                                            {/*<Popover placement="top" content={contentInfo}
                                                              overlayClassName="infoContentLogement">
                                                        <div className="info-logement">
                                                            <img src="/assets/img/info-circled.png" alt="info"/>
                                                        </div>
                                                    </Popover>*/}
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <div className="nombre">
                                                            <Input
                                                                disabled={logement?.superficie?.length > 0 ? true : false}
                                                                className="nombre-input"
                                                                value={supLogement === 0 ? "" : supLogement}
                                                                placeholder={supLogement === 0 ? "0" : ""}
                                                                prefix={<div className="valeur">m²</div>}
                                                                onChange={(e) => handleChange(e.target.value, "logement")}
                                                                onBlur={(e) => handleBlur(e.target.value, "logement")}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <br/> <br/>
                                <div className="subTitle">
                                    <img src="/assets/img/info-pop-up_colored.png" className="title-img" alt="info"/>
                                    Forfait Tout compris pour les villas</div>
                                <div className="blc-content surface-a-nettoyer-second-p sans-pro-light">
                                    <p>Pour les villas, la durée de la prestation de ménage est illimitée (ILL) et le forfait tarifaire défini inclut le nettoyage intégral de tout
                                        le logement, avec fourniture du matériel et des produits de nettoyage. Pour un maximum d’efficacité l’intervention se déroule
                                        obligatoirement avec une équipe de plusieurs personnes.</p>
                                    <br/>
                                    <p>Remarques :
                                        <ul>
                                            <li>Seules les vitres accessibles de plein pied sont nettoyées</li>
                                            <li>Le nettoyage des terrasses, vérandas est inclus dans le forfait</li>
                                            <li>Kliner n’effectue pas l’entretien des piscines et jardins</li>
                                            <li>Seul le ramassage de déchet (hors objet lourd encombrant) est effectué sur les cours, allées et jardins (dans la limite d’un
                                                périmètre raisonnable directement autour des espaces de vie)</li>
                                        </ul>
                                    </p>

                                </div>
                            </>}
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    );
}

export default SurfaceView;