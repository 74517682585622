import React, {useState, useEffect} from "react";
import {
    Col, Divider,
    Input, message,
    Modal, Row,
} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {setRefresh} from "../services/planning-slice";
import {
    usePrestationUpdateLingeMutation
} from "../services/aminitie-linge-api";
import {useDispatch} from "react-redux";

const ModifierLitsModalView = ({idPrestationDetail, sipmle, double, king, status, handleCancel}) => {
    const [litSimple, setLitSimple] = useState(0);
    const [litDouble, setLitDouble] = useState(0);
    const [litKing, setKing] = useState(0);
    const [updateLit] = usePrestationUpdateLingeMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        setLitSimple(sipmle);
        setLitDouble(double);
        setKing(king);
    }, [idPrestationDetail, status]);

    const handleUpdate = (data) => {
        updateLit({id: idPrestationDetail, data: data}).unwrap()
                .then(() => {
                    message.success("Information enregistrée.");
                    dispatch(setRefresh(true));
                })
                .catch((error) => {
                    message.error("Erreur lors de l'enregistrement de l'information:"+error.data.message)
                });
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={300}
            className="controle-qualite"
        >

            <div className="controle">
                <div className="mintitle custom-padding">
                    Modifier les kits de linge
                </div>
                <div className="description-controle sans-pro-regular color-A7A7A7">
                    Kits à fournir pour cette prestation
                </div>
                <br/>
                <div className="listKit">
                    <Row>
                        <Col lg={16}>
                            <div className="label">Kit pour lit Simple</div>
                        </Col>
                        <Col>
                                <span className={"moins-kit " + (litSimple === 0 ? "disable-Button" : "")} onClick={litSimple === 0 ? null : () => {
                                    setLitSimple(litSimple - 1);
                                    handleUpdate({lit_simple: litSimple - 1});
                                }}> <MinusOutlined /> </span>
                            <div className="nombre4">
                                <Input className="nombre-input-alter-kit" value={litSimple}
                                       onChange={(e) => null}
                                       onBlur={(e) => null} />
                            </div>
                            <span className="plus-kit"
                                  onClick={() => {
                                      setLitSimple(litSimple + 1);
                                      handleUpdate({lit_simple: litSimple + 1});
                                  }}> <PlusOutlined />
                                            </span>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col lg={16}>
                            <div className="label">Kit pour lit Double</div>
                        </Col>
                        <Col>
                            <span className={"moins-kit " + (litDouble === 0 ? "disable-Button" : "")} onClick={litDouble === 0 ? null : () => {
                                setLitDouble(litDouble - 1);
                                handleUpdate({lit_double: litDouble - 1});
                            }}> <MinusOutlined /> </span>
                            <div className="nombre4">
                                <Input className="nombre-input-alter-kit" value={litDouble}
                                       onChange={(e) => null}
                                       onBlur={(e) => null} />
                            </div>
                            <span className="plus-kit"
                                  onClick={() => {
                                      setLitDouble(litDouble + 1);
                                      handleUpdate({lit_double: litDouble + 1});
                                  }}> <PlusOutlined />
                                            </span>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col lg={16}>
                            <div className="label">Kit pour lit King Size</div>
                        </Col>
                        <Col>
                            <span className={"moins-kit " + (litKing === 0 ? "disable-Button" : "")} onClick={litKing === 0 ? null : () => {
                                setKing(litKing - 1);
                                handleUpdate({lit_king: litKing - 1});
                            }}> <MinusOutlined /> </span>
                            <div className="nombre4">
                                <Input className="nombre-input-alter-kit" value={litKing}
                                       onChange={(e) => null}
                                       onBlur={(e) => null} />
                            </div>
                            <span className="plus-kit"
                                  onClick={() => {
                                      setKing(litKing + 1);
                                      handleUpdate({lit_king: litKing + 1});
                                  }}> <PlusOutlined />
                                            </span>
                        </Col>
                    </Row>
                </div>
                <br/>
                <br/>
                <br/>
                <div style={{textAlign:"center"}}>
                    <a href="#" onClick={() => handleCancel()}
                       className="kit-confirmer sans-pro-regular">Confirmer</a>
                </div>
            </div>

        </Modal>
    );
};
export default ModifierLitsModalView;
