import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message, Select} from "antd";
import { useDispatch } from "react-redux";

const AjusterPrixPrestationModalView = ({ status, handleCancel }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);




    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="ajusterLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Quelle situation s’est présentée ?</div>
                    </div>
                    <Row>
                        <Col lg={24}>
                           <Select placeholder="Choisir une option" className="change-price-option">
                               <option value="">Le partenaire a acheté les amenities fournis</option>

                               <option value="">Le partenaire a fait un déplacement à vide</option>

                               <option value="">Le partenaire a effectué 1 heure de plus</option>

                               <option value="">Le partenaire a effectué 2 heures de plus</option>

                               <option value="">Le partenaire a effectué 3 heures de plus</option>

                           </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <ul className="old-choice">
                                <li>Le partenaire a acheté les amenities fournis</li>
                                <li>Le partenaire a effectué 1 heure de plus</li>
                            </ul>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => null}
                                   className="suivant sans-pro-regular">Confirmer</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default AjusterPrixPrestationModalView;
