import React, { useState } from 'react';
import { Row, Col, Divider, Input, message, Spin, Popover } from "antd";
import { useLogementUpdateMutation } from '../services/logement-api';
import {useDispatch} from "react-redux";
import {setRefresh} from "../services/logement-slice";
const { TextArea } = Input;


function InstructionsMenageView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [logementUpdate] = useLogementUpdateMutation();
    const [instructionAdresse, setInstructionAdresse] = useState(logement.instruction_adresse);
    const dispatch = useDispatch();

    const handleUpdateInstruction = () => {

            logementUpdate({ id: logement.id, data: ({ instruction_adresse: instructionAdresse }) }).unwrap()
                .then(() => {
                    dispatch(setRefresh(true));
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                })
                .catch(() => { setIsLoading(false) });

    };

    return (
        <Spin spinning={isLoading}>
            <div className="surface-a-nettoyer blc-right">
                {contextHolder}
                <div className="title">Instructions de ménage (facultatif)</div>
                <Divider />
                <div className="contentToScroll scroll-none">
                    <Row gutter={24}>
                        <Col lg={14}>
                            <div className="subTitle">Consignes d’entretien</div>
                            <p>
                                Laissez toute consigne qui vous semble utile pour l’entretien de ce logement. Si vous <br/>
                                n’en donnez pas, les aides ménagères suivront <a href="#">les standards Kliner</a>.
                            </p>
                            <br/>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col lg={14}>
                        <TextArea
                            value={instructionAdresse}
                            onBlur={handleUpdateInstruction}
                            onChange={(e) => setInstructionAdresse(e.target.value)}
                            rows={30}
                        />
                    </Col>
                </Row>
                </div>
            </div>
        </Spin>
    );
}

export default InstructionsMenageView;