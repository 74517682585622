import React, { useState, useEffect, useRef } from "react";
import {Row, Col, Input, message, Spin, Popover, Divider} from "antd";
import { apiKey, extractAddress, loadAsyncScript, mapApiJs } from "../../../../utils/googlemap-utils";
import { useLogementUpdateMutation } from "../../services/logement-api";
import {setRefresh} from "../../services/logement-slice";
import {useDispatch} from "react-redux";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
const { TextArea } = Input;


function LingeSaleView({lit, douche,  logement }) {
    const [valued, setValue] = useState(logement.laundryManagementInstruction);
    const [messageApi, contextHolder] = message.useMessage();
    const searchInput = useRef(null);
    const [logementUpdate] = useLogementUpdateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [address, setAddress] = useState();
    const dispatch = useDispatch();
    const [litUnePlace, setLitUnePlace] = useState(logement.lit_simple == null ? 0 : parseInt(logement.lit_simple));
    const [litDeuxPlace, setLitDeuxPlace] = useState(logement.lit_double == null ? 0 : parseInt(logement.lit_double));
    const [litKQSize, setLitKQSize] = useState(logement.lit_king == null ? 0 : parseInt(logement.lit_king));
    const [doucheSdbain, setDoucheSdbain] = useState(logement.douche == null  ? 1 : parseInt(logement.douche) === 0 ? 1 : parseInt(logement.douche));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (lit) {
            lit({
                unePlace: litUnePlace,
                deuxPlace: litDeuxPlace,
                kingSize: litKQSize,
            });
        }
    }, [lit, litUnePlace, litDeuxPlace, litKQSize]);

    useEffect(() => {
        if (douche) {
            douche(doucheSdbain);
        }
    }, [douche, doucheSdbain]);


    const initMapScript = () => {
        if (window.google) return Promise.resolve();
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    };

    const initAutocomplete = (searchInput) => {
        if (!searchInput) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput);
        autocomplete.setFields(["address_component", "geometry", "formatted_address", "name"]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            setAddress(extractAddress(place));
        });
    };


    useEffect(() => {
        initMapScript().then(() => initAutocomplete(searchInput.current));
    }, []);


    const handleAdressSaveFunction = () => {
        if (address) handleAdressSave();
    };

    useEffect(handleAdressSaveFunction, [address]);

    const handleAdressSave = async () => {
        if (address) {
            const data = ({
                laundryManagementAdresse: address?.street_number + " " + address?.street + ", " + address?.city,
            })
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: data }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    dispatch(setRefresh(true));
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                });
        }
    };


    const handleBlur = (e, value) => {
        if (valued?.length > 0) {
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: ({ laundryManagementInstruction: valued }) }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    setIsLoading(false)
                })
                .catch(() => setIsLoading(false)
                );
        }
        if (value === "litUnePlace") {
            setLitUnePlace(e);
            handleUpdate({ lit_simple: parseInt(e) })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
            handleUpdate({ lit_double: parseInt(e) })
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
            handleUpdate({ lit_king: parseInt(e) })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(e);
            handleUpdate({ douche: parseInt(e) })
        }
    };

    const contentInfo = (
        <div className="infoLinge">
            <p>
                Si l’adresse fournie est incorrecte ou si <br/>
                le Lavomatic n’est pas ouvert à l’heure <br/>
                de la prestation, le linge sale sera <br/>
                ramené au logement et la prestation <br/>
                sera tout de même facturée
            </p>
        </div>
    );


    const changeNbMoins = (value) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(litUnePlace - 1);
            handleUpdate({ lit_simple: parseInt(litUnePlace) - 1 })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(litDeuxPlace - 1);
            handleUpdate({ lit_double: parseInt(litDeuxPlace) - 1 })
        }
        if (value === "litKQSize") {
            setLitKQSize(litKQSize - 1);
            handleUpdate({ lit_king: parseInt(litKQSize) - 1 })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(doucheSdbain - 1);
            handleUpdate({ douche: parseInt(doucheSdbain) - 1 })
        }
    };

    const handleChange = (e, value) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(e);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(e);
        }
    };

    const changeNbPlus = (value, nb) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(nb);
            handleUpdate({ lit_simple: nb })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(parseInt(litDeuxPlace) + 1);
            handleUpdate({ lit_double: parseInt(litDeuxPlace) + 1 })
        }
        if (value === "litKQSize") {
            setLitKQSize(parseInt(litKQSize) + 1);
            handleUpdate({ lit_king: parseInt(litKQSize) + 1 })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(parseInt(doucheSdbain) + 1);
            handleUpdate({ douche: parseInt(doucheSdbain) + 1 })
        }
    };

    const handleUpdate = (data) => {
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success',
                    duration: 0.5
                });
            })
            .catch((error) => {
                message.error(error);
            });
        setLoading(false)
    };

    return (
        <div className="lavomatic blc-right">
            {contextHolder}
            <div className="subTitle">Lits & douches dans le logement * (obligatoire) </div>
            <Spin spinning={loading}>
                <Row gutter={24}>
                    <div className="blc-content">
                        <br />
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits une (1) place </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litUnePlace === 0 ? "disable-Button" : "")}
                                                  onClick={litUnePlace === 0 ? null : () => changeNbMoins("litUnePlace")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input-alter" value={litUnePlace}
                                                       onChange={(e) => handleChange(e.target.value, "litUnePlace")}
                                                       onBlur={(e) => handleBlur(e.target.value, "litUnePlace")} />
                                            </div>
                                            <span className="plus"
                                                  onClick={() => changeNbPlus("litUnePlace", (parseInt(litUnePlace) + 1))}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits deux (2) places </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litDeuxPlace === 0 ? "disable-Button" : "")}
                                                  onClick={litDeuxPlace === 0 ? null : () => changeNbMoins("litDeuxPlace")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input" value={litDeuxPlace}
                                                       onChange={(e) => handleChange(e.target.value, "litDeuxPlace")}
                                                       onBlur={(e) => handleBlur(e.target.value, "litDeuxPlace")} />
                                            </div>
                                            <span className="plus"
                                                  onClick={() => changeNbPlus("litDeuxPlace")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits KING ou QUEEN size </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litKQSize === 0 ? "disable-Button" : "")}
                                                  onClick={litKQSize === 0 ? null : () => changeNbMoins("litKQSize")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input-alter" value={litKQSize}
                                                       onChange={(e) => handleChange(e.target.value, "litKQSize")}
                                                       onBlur={(e) => handleBlur(e.target.value, "litKQSize")} />
                                            </div>
                                            <span className="plus"
                                                  onClick={() => changeNbPlus("litKQSize")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de douche ou salle de bain </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (doucheSdbain === 1 ? "disable-Button" : "")}
                                                  onClick={doucheSdbain === 1 ? null : () => changeNbMoins("doucheSdbain")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input" value={doucheSdbain}
                                                       onChange={(e) => handleChange(e.target.value, "doucheSdbain")}
                                                       onBlur={(e) => handleBlur(e.target.value, "doucheSdbain")} />
                                            </div>
                                            <span className="plus"
                                                  onClick={() => changeNbPlus("doucheSdbain")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </div>
                    <br />
                </Row >
            </Spin >
            {/*<Spin spinning={isLoading}>
                <Row gutter={24}>
                    <Col lg={24}>
                        <p>Veuillez indiquer l’adresse d’un Lavomatic à proximité du logement* (obligatoire)
                            <Popover placement="top" content={contentInfo} overlayClassName="infoContentLinge">
                                <div className="info-linge">
                                    <img src="/assets/img/info-circled.png" alt="info"/>
                                </div>
                            </Popover>
                        </p>
                        <Row>
                            <Col lg={16}>
                                <input type="text" className="ant-input" style={{height: "48px"}} ref={searchInput}
                                       placeholder={logement?.laundryManagementAdresse ? logement?.laundryManagementAdresse : "Saisir l’adresse"}/>
                            </Col>
                        </Row>
                        <br/> <br/><br/> <br/> <br/>
                    </Col>

                    <Col lg={24}>
                        <p>Où doit-on déposer le linge lavé dans le logement ? (recommandé)</p>
                        <TextArea
                            className="radiusTextArea"
                            value={valued}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={handleBlur}
                            rows={8}
                        />
                    </Col>
                </Row>
            </Spin> */}
        </div>
    );
}

export default LingeSaleView;