import React, { useState, useEffect } from "react";
import {
    Input,
    Modal,
    Row,
    Col,
    Select,
    Alert,
    Button,
    Space, Form, Divider
} from "antd";

import { useUserUpdateMutation } from "../../services/profil-api";

const InformationModalView = ({ status, handleCancel, userInfo, addr, closeModal }) => {
    const [form] = Form.useForm();
    const [newUser, setNewUser] = useState({});
    const [newAddr, setNewAddr] = useState({});
    const [userUpdate] = useUserUpdateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (userInfo && addr) {
            form.setFieldsValue({
                company_name: userInfo.entreprise || `${userInfo.firstname} ${userInfo.lastname}`,
                address: addr.adresse,
                address2: userInfo.billingAddress2,
                city: addr.ville,
                postalCode: addr.codepostal,
                country: addr.pays,
                email: userInfo.email,
                emailCC: userInfo.customerEmailCC,
                vatNumber: userInfo.vatNumber
            });
            setNewAddr(addr);
        }
    }, [form, addr, userInfo]);

    const userInfoUpdate = (field, value) => {
        newUser[field] = value;
    };

    const userAddrUpdate = (field, value) => {
        console.log(value)
        newAddr[field] = value;
        newUser["residence_entreprise"] = JSON.stringify(newAddr);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        userUpdate({ id: userInfo.id, data: newUser })
            .unwrap()
            .then(() => {
                form.resetFields();
                handleCancel();
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setError(error.data.message);
            });
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{ top: 100 }}
            closable={true}
            onCancel={() => {
                closeModal();
            }}
            width={850}
            className="editer-information-facturation"
        >

            <div className="informations">
                <div className="contentIfon">
                    <Form
                        name="basic"
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        layout="vertical"
                        className="form-edit-profil"
                    >
                        <Row>
                            <Col lg={12} className="first-block">
                                <div className="title">
                                    <div className="icone">
                                        <img src="./assets/img/data-icon.png" alt="image" />
                                    </div>
                                    <div className="text sans-pro-semi-bold">
                                        Adresse de facturation
                                    </div>
                                </div>
                                <Row>
                                    <Col lg={24}>
                                        <div className="input-barrier">
                                        <label htmlFor="company_name" className={"force-label-effet color-5B6E8C"}>Nom / Nom de l'entreprise *</label>
                                        <Form.Item
                                            name="company_name"
                                            className="input-barrier"
                                            rules={[{ required: true, message: "Champs requis!" }]}>

                                            <Input id="company_name" placeholder="" onChange={e => userInfoUpdate("entreprise", e.target.value)} />
                                        </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={24}>
                                        <div className="input-barrier">
                                        <label htmlFor="address" className={"force-label-effet color-5B6E8C"}>Adresse ligne 1 *</label>
                                        <Form.Item
                                            name="address"
                                            className="input-barrier"
                                            rules={[{ required: true, message: "Champs requis!" }]}>

                                            <Input id="address" placeholder="" onChange={e => userAddrUpdate("adresse", e.target.value)} />
                                        </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={24}>
                                        <div className="input-barrier">
                                            <label htmlFor="address2" className={"force-label-effet color-5B6E8C"}>Adresse ligne 2</label>
                                            <Form.Item
                                                name="address2"
                                                className="input-barrier"
                                                rules={[{ required: false, message: "Champs requis!" }]}>

                                                <Input id="address2" placeholder="" onChange={e => userInfoUpdate("billingAddress2", e.target.value)} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                        <Col lg={13}>
                                            <div className="input-barrier">
                                                <label htmlFor="city" className={"force-label-effet color-5B6E8C"}>Ville</label>
                                                <Form.Item
                                                    name="city"
                                                    className="input-barrier"
                                                    rules={[{ required: true, message: "Champs requis!" }]}>

                                                    <Input id="city" placeholder="" onChange={e => userAddrUpdate("ville", e.target.value)} />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col lg={1}>
                                        </Col>
                                        <Col lg={10}>
                                            <div className="input-barrier">
                                                <label htmlFor="postalCode" className={"force-label-effet color-5B6E8C"}>Code postal *</label>
                                                <Form.Item
                                                    name="postalCode"
                                                    className="input-barrier"
                                                    rules={[{ required: true, message: "Champs requis!" }]}>

                                                    <Input id="postalCode" placeholder="" onChange={e => userAddrUpdate("codepostal", e.target.value)} />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    <Col lg={24}>
                                        <Form.Item
                                            name="country"
                                            className="input-barrier"
                                            rules={[{required: true, message: "Champs requis!"}]}>
                                            <Select
                                                defaultValue="France"
                                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add"/> </div>}
                                                onChange={e => userAddrUpdate("pays", e)}
                                                options={[
                                                    {
                                                        value: 'France',
                                                        label: 'France',
                                                    },
                                                    {
                                                        value: 'Royaume-Uni',
                                                        label: 'Royaume-Uni',
                                                    },                                                    
                                                ]} />
                                        </Form.Item>
                                    </Col>

                                    {
                                        /*
                                        <Col lg={9}>
                                        <Form.Item
                                            name="email"
                                            className="input-barrier"
                                            rules={[{required: true, message: "Champs requis!"}]}>
                                            <Select
                                                defaultValue="EU V"
                                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add"/> </div>}
                                                options={[
                                                    {
                                                        value: 'EU V',
                                                        label: 'EU V',
                                                    },
                                                    {
                                                        value: 'Tout',
                                                        label: 'Tout',
                                                    },
                                                ]} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={1}>

                                         </Col>
                                    <Col lg={14}>
                                        <Form.Item
                                            name="email"
                                            className="input-barrier"
                                            rules={[{required: true, message: "Champs requis!"}]}>
                                            <label htmlFor="email" className={"force-label-effet color-5B6E8C"}>Numéro de TVA (facultatif) *</label>
                                            <Input id="email" placeholder=""  value="brice.william@gmail.com"/>
                                        </Form.Item>
                                    </Col>
                                        */
                                    }
                                </Row>
                            </Col>
                            <Col lg={12} className="second-block">
                                <div className="title">
                                    <div className="icone">
                                        <img src="./assets/img/invoice-icon.png" alt="image" />
                                    </div>
                                    <div className="text sans-pro-semi-bold">
                                        Destinataire des factures
                                    </div>
                                </div>

                                <Row>
                                    <Col lg={24}>
                                        <div className="input-barrier">
                                        <label htmlFor="email" className={"force-label-effet color-5B6E8C"}>Email 1</label>
                                        <Form.Item
                                            name="email"
                                            className="input-barrier"
                                            rules={[{ required: true, message: "Champs requis!" }]}>

                                            <Input id="email" placeholder="" onChange={e => userInfoUpdate("billingAddress1", e.target.value)} />
                                        </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={24}>
                                        <div className="input-barrier">
                                        <label htmlFor="emailCC" className={"force-label-effet color-5B6E8C"}>Email CC</label>
                                        <Form.Item
                                            name="emailCC"
                                            className="input-barrier"
                                            rules={[{ required: false, message: "Champs requis!" }]}>

                                            <Input id="emailCC" placeholder="" onChange={e => userInfoUpdate("customerEmailCC", e.target.value)} />
                                        </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {error && <Alert message={error} type="error" closable />}
                <div className="footer-modal">
                     <Divider/>
                    <Row>
                        <Col lg={18} className="button-left">
                            <a href="#" onClick={() => closeModal()} className="annuler"><span> Annuler</span></a>
                        </Col>
                        <Col lg={6} className="button-right">
                            <Button type="primary" onClick={() => handleSubmit()}
                               className="suivant sans-pro-regular" loading={isLoading}>Mettre à jour</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default InformationModalView;
