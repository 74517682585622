import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const aminitieApi = createApi({
    reducerPath: "aminitieApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Aminitie"],
    endpoints: (builder) => ({
        aminitieFetch: builder.query({
            query: (payload) => ({
                url: `api/v1/cart/prestation/${payload.id}/amenitie`,
                method: "GET",
            }),
            providesTags: ["Aminitie"],
        }),
        prestationUpdateAminitie: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/cart/prestation/`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Aminitie"],
        }),
        prestationUpdateAminitieMoins: builder.mutation({
            query: (payload) => ({
                url: `api/v1/cart/prestation/decrease`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Aminitie"],
        }),
        prestationUpdateAminitiePrice: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/prestation/details/${payload.id}`,
                method: "PATCH",
                body: payload.data,
            }),
            invalidatesTags: ["Aminitie"],
        }),
    }),
});

export const {
    useAminitieFetchQuery,
    usePrestationUpdateAminitieMutation,
    usePrestationUpdateAminitieMoinsMutation,
    usePrestationUpdateAminitiePriceMutation,
} = aminitieApi;

export const lingeApi = createApi({
    reducerPath: "lingeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Linge"],
    endpoints: (builder) => ({
        lingeFetch: builder.query({
            query: (payload) => ({
                url: `api/v1/cart/prestation/${payload.id}/linge`,
                method: "GET",
            }),
            invalidatesTags: ["Linge"],
        }),
        prestationUpdateLinge: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/prestation/details/${payload.id}`,
                method: "PATCH",
                body: payload.data,
            }),
            invalidatesTags: ["Linge"],
        }),
    }),
});

export const {
    useLingeFetchQuery,
    usePrestationUpdateLingeMutation,
} = lingeApi;
