import React, { useState } from "react";
import {Badge, Button, Carousel, Col, Input, Modal, Progress, Row, Select} from "antd";


const Slide2CGUModalPage = ({itemSelected}) => {
    const carouselRef = React.createRef();

    return (
        <div className='Slide1CGUModalPage'>
            <div className="headTitle">
                <img src="/assets/img/logo.png" alt=""/>
            </div>
            <div className="content">
                <Row>
                    <Col lg={12}>
                       <div className="imageSide">
                           <img src="/assets/img/Visuel pop up 2.png" alt=""/>
                       </div>
                    </Col>
                    <Col lg={12}>
                        <div className="textSide">
                            <div className="h3">Partout en France, nous vous mettons à disposition
                                des équipes de ménage professionnelles à la
                                demande</div>
                            <div className="h2">C’est simple, et nous sommes à vos
                                côtés à chaque instant</div>
                            <div className="listElement">
                                <div className="element">
                                    <div className="list">
                                        1.
                                    </div>
                                    <div className="text">
                                        Acceptez nos conditions générales de ventes.
                                    </div>
                                </div>
                                <div className="element">
                                    <div className="list">
                                        2.
                                    </div>
                                    <div className="text">
                                        Créez vos logements en un clic et demandez-nous d’y
                                        automatiser un ménage premium.
                                    </div>
                                </div>
                                <div className="element">
                                    <div className="list">
                                        3.
                                    </div>
                                    <div className="text">
                                        Des besoins particuliers ? Indiquez-les à votre Chargé de
                                        compte Kliner pour une expérience personnalisée.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Slide2CGUModalPage;