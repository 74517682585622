import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker, Progress
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useDispatch, useSelector} from "react-redux";
import {IoArrowForwardCircleOutline} from "react-icons/io5";
import {usePrestationEvaluateCreateMutation, usePrestationEvaluateUpdateMutation} from "../../../services/planning-api";
import {setRefresh} from "../../../services/planning-slice";
const {TextArea} = Input;
const EvaluationModalView = ({status, handleCancel, idQuality, prestationId}) => {
    const [qualityControl] = usePrestationEvaluateUpdateMutation();
    const [qualityControlCreate] = usePrestationEvaluateCreateMutation();
    const [selected, setSelected] = useState("");
    const [selectedMotif, setSelectedMotif] = useState([]);
    const [selectedText, setSelectedText] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const reason = [
        "Qualité du ménage",
        "Ponctualité",
        "Qualité du linge",
        "Propreté du linge",
        "Produits des kits d’accueil",
        "Respect des instructions de ménage",
        "Service client Kliner",
        "Informations de suivi des prestations",
        "Autre",
    ];

    useEffect(() => {
        setSelected(idQuality?.customer_stars !== null ? parseInt(idQuality?.customer_stars).toString() : "");
        setSelectedMotif([]);
        setSelectedText("");
        if(idQuality?.customer_comment !== null && idQuality?.customer_comment !== ""){
            let spliter1 = idQuality?.customer_comment.split("#")[0].split(",");
            setSelectedText(idQuality?.customer_comment.split("#")[1]);
            setSelectedMotif(spliter1 ?? []);
        }
    }, [idQuality]);
    const handleUpdateQuality = async () => {
        setLoading(true);
        //console.log({ id: idQuality?.id, data: {customer_stars: parseInt(selected), customer_comment: `${selectedMotif}#${selectedText}`}});
        qualityControl({ id: idQuality?.id, data: {customer_stars: parseInt(selected), customer_comment: `${selectedMotif}#${selectedText}`} })
            .unwrap()
            .then((res) => {
                setLoading(false);
                dispatch(setRefresh(true));
                message.success("Evaluation enregistrée!");
                handleCancel();
            })
            .catch((error) => {
                setLoading(false);
                message.error("Evaluation non enregistrée: "+ error);
            });
    }

    const handleCreateQuality = async () => {
        setLoading(true);
        //console.log({ id: idQuality?.id, data: {customer_stars: parseInt(selected), customer_comment: `${selectedMotif}#${selectedText}`}});
        qualityControlCreate({data: {prestation: prestationId, customer_stars: parseInt(selected), customer_comment: `${selectedMotif}#${selectedText}`} })
            .unwrap()
            .then((res) => {
                setLoading(false);
                dispatch(setRefresh(true));
                message.success("Evaluation enregistrée!");
                handleCancel();
            })
            .catch((error) => {
                setLoading(false);
                message.error("Evaluation non enregistrée: "+ error);
            });
    }

   //console.log(selectedMotif);
   //console.log(idQuality);
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={500}
            className="evaluation-service"
        >
            <Spin spinning={loading}>
            <div className="evaluation">
                <div className="mintitle custom-padding">
                    Évaluez cette prestation
                </div>
                <div className="description-controle">
                    Comment avez-vous apprécié nos services ?
                </div>
                <div className="sticker">
                    <Row>
                        <Col lg={5}><div className={"only-sticker "+(selected === "1" ? "active" : "")} onClick={()=>  setSelected("1")}>🥲</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "2" ? "active" : "")} onClick={()=>  setSelected("2")}>🙁️</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "3" ? "active" : "")} onClick={()=> setSelected("3")}>😕</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "4" ? "active" : "")} onClick={()=>  setSelected("4")}>🙂</div></Col>
                        <Col lg={4}><div className={"only-sticker "+(selected === "5" ? "active" : "")} onClick={()=>  setSelected("5")}>😍</div></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={5}><div className="text">Nul</div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={4}><div className="text">Excellent</div></Col>
                    </Row>
                </div>
                <Divider />
                <br/>
                <div  className="description-controle">
                    Dites-nous ce que nous pourrions améliorer
                </div>
                <div className="choice-amelioration">
                    {
                        reason.map((item, index) => {
                            return <div className={"element "+(selectedMotif?.includes(item) ? " activeMotif" : "")} key={"element_" + index} onClick={()=> selectedMotif?.includes(item) ? setSelectedMotif(selectedMotif.filter(element => element !== item)) : setSelectedMotif([...selectedMotif, item])} style={{cursor:"pointer"}}>{item}</div>;
                        })
                    }
                </div>
                <br/>
                <div className="description-controle">
                    Rajoutez quelques mots si vous souhaitez
                </div>
                <div className="textarea">
                    <TextArea
                        value={selectedText}
                              placeholder="Votre feedback ici"
                              onChange={(e) => setSelectedText(e.target.value)}
                              rows={3}/>
                </div>
                {/*<div className="add-file">
                    <div className="image">
                        <img src="./assets/img/upload-icon.png" alt="file"/>
                    </div>
                    <div className="text">Cliquez pour télécharger ou glisser-deposer un fichier</div>
                </div>*/}

                <div className="footer-modal">
                    <Row>
                        <Col lg={13} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler</span></a>
                        </Col>
                        <Col lg={11} className="button-right">
                            <a href="#" onClick={() => selected === "" ? null : (idQuality === null ? handleCreateQuality() : handleUpdateQuality())}
                               className={"suivant sans-pro-regular "+(selected === "" ? "disable" : "")}>Envoyer du feedback</a>
                        </Col>
                    </Row>
                </div>
            </div>

            </Spin>
        </Modal>
    );
};
export default EvaluationModalView;
