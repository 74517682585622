import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Space, Row, Col } from 'antd';
import { show } from "@intercom/messenger-js-sdk";
import { IoIosSchool } from "react-icons/io";
import Evaluation from './evaluation';
import { IoIosRadioButtonOff } from "react-icons/io";
import AgrandirView from "./agrandir";
import DemandeView from "./demande-cours";
import ProgrammerManuellementModalView from "../planning/modal/programmer-manuellement";
import ValidProgrammationModalView from "../planning/modal/planning-success";
import LogementCreate from "../logements/create";
import PubModalPage from "./modal-pub";
import { useLogementCreateMutation } from '../logements/services/logement-api';
import { setRefresh } from '../logements/services/logement-slice';
import "./styles/style.scss"
import "./styles/styleMax1020.scss"
import SuspendreModalView from "./suspendu";
import * as dashboardActions from './services/dashboard-slice'


function DashBoardPage() {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalDemand, setShowModalDemand] = useState(false);
    const [showModalLogement, setShowModalLogement] = useState(false);
    const [valid, setValid] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalLogementCreate, setShowModalLogementCreate] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(false);
    const [showModalPub, setShowModalPub] = useState(false);
    const [logementCreate] = useLogementCreateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [logementId, setLogementId] = useState();
    const dispatch = useDispatch();
    const showNews = sessionStorage.getItem('showNews');
    const [error, setError] = useState(false);

    const [showModalSuspendre, setShowModalSuspendre] = useState(false);
    useEffect(() => {
        if(showNews !== "false" && user?.isCguValidated !== null && !user?.isCguValidated){
            setTimeout(() => {
                setShowModalPub(true);
            }, 1000);
        }
    }, [user, showNews]);

    useEffect(() => {
        if (valid) {
            setShowModalDemand(true);
        }
        setValid(false);
    }, [valid]);


    // CREATE LOGEMENT
    const handleCreate = async () => {
        const data = ({ libelle: "Logement de " + user.firstname, user: user.id })
        setIsLoading(true);
        logementCreate(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setShowModalLogement(true)
                setLogementId(res.id);
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    return (
        <div className="dashboard-page-padding">
            <div className='page-header' >
                <div className='page-title'>
                    <h1>Tableau de bord</h1>
                </div>
                <Space>
                    <Button type="default" className='ant-btn-icon btn-default-gray pt-5' onClick={() => handleCreate()}>
                        <div className="iconSide">
                            <img src="../assets/img/add-logement.png" className='icon' alt="" />
                        </div>
                        Ajouter un logement
                    </Button>

                    <Button type="primary" className='ant-btn-icon pt-5 btn-primary-effect' onClick={() => setShowModalProgrammerService(true)}>
                        <div className="iconSide">
                            <img src='assets/img/icon-white-stars.png' className='icon' />
                        </div>
                        Programmer un ménage
                    </Button>
                </Space>
            </div>

            <div className='page-content'>
                <div className='section-1'>
                    <Row>
                        <Col lg={15}>
                            <div className='blc-left'>
                                <div className='video'>
                                    <iframe title='kliner' width="100%" height="428"
                                        src="https://www.youtube.com/embed/obdvuf5npq0" frameBorder="0"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className='blc-right' style={{ paddingLeft: "15px" }}>
                                <p className='section-title-2'><IoIosSchool className='icon' /> Guide de démarrage</p>
                                <div className='blc-content'>
                                    <p> Étapes à suivre pour une expérience optimale ! </p>
                                    <ul className="stepStart">
                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <NavLink to={"#"} onClick={() => handleCreate()}>Créer vos premiers logements</NavLink> <br />
                                                <span>Estimez les frais de ménage pour chaque logement</span>
                                            </div>
                                        </li>

                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <a href="https://help.kliner.com/fr/articles/9360002" target='_blank'>Compléter le cahier des charges d’entretien</a>
                                                <br />
                                                <span>Précisez tous vos besoins pour un service optimal</span>
                                            </div>
                                        </li>

                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <a href="https://help.kliner.com/fr/articles/9349394" target='_blank'>Demander une équipe de ménage</a> <br />
                                                <span>Trouvez le personnel pour vos prestations en un clic</span>
                                            </div>
                                        </li>

                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <a href="https://help.kliner.com/fr/articles/9349418-etape-5-programmez-vos-prestations-de-menage#h_2fbda0c381" target='_blank'>Automatiser le planning</a> <br />
                                                <span>Simplifiez l’organisation des prestations</span>
                                            </div>
                                        </li>

                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <a href="https://help.kliner.com/fr/articles/9365834" target='_blank'>Paramétrer le suivi des prestations</a> <br />
                                                <span>Déterminez le niveau de contrôle qualité adapté</span>
                                            </div>
                                        </li>

                                        <li>
                                            <IoIosRadioButtonOff className='icon' />
                                            <div>
                                                <a href="https://help.kliner.com/fr/articles/9334607" target='_blank'>Parrainer des amis</a> <br />
                                                <span>Gagnez 6 000€ de réduction sur vos frais de ménage</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>


                <div className='section-2'>
                    <p className='section-title'>Besoin d’aide, {user?.firstname} ?</p>
                    <Row gutter={24}>
                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-calendar.png' className='img i1' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4>Réserver une démo</h4>
                                        <p>Découvrez toute la puissance de la plateforme Kliner.</p>
                                    </div>
                                </div>
                                <a href='https://app.lemcal.com/@rzdrilic/discuter-avec-lequip' target="_blank">
                                    <Button type="default" className='btn-lg'>Contacter l’équipe commerciale</Button>
                                </a>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-solution.png' className='img i1' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4 className='disable'>Résoudre une situation en cours</h4>
                                        <p className='disable'>Vous rencontrez un problème sur une prestation ou une
                                            facture ?</p>
                                    </div>
                                </div>
                                <Button className='btn-lg disable'>
                                    <span>Trouver rapidement une solution</span>
                                </Button>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-support.png' className='img i1' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4>Contacter le support</h4>
                                        <p>Nos équipes sont disponibles 7j/7 pour vous accompagner.</p>
                                    </div>
                                </div>
                                <Button id="#contacterSupportId" type="default" className='btn-lg' onClick={show}>Contacter le support </Button>
                            </div>
                        </Col>
                    </Row>

                    <br />
                    <Row gutter={24}>
                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-activite.png' className='img i2' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4>Besoin d’agrandir votre parc ?</h4>
                                        <p>Trouvez des propriétaires prêts à vous confier la gestion de leurs biens.</p>
                                    </div>
                                </div>
                                <Button type="default" className='btn-lg' onClick={() => (setShowModal2(true), dispatch(dashboardActions.setEvaluation({ isEligible: true })))}>Développer votre activité</Button>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-augmenter.png' className='img i2' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4 className="disable">Augmenter votre taux d’occupation</h4>
                                        <p className="disable">Notre Intelligence artificielle trouve des voyageurs pour booster vos
                                            réservations.</p>
                                    </div>
                                </div>
                                <Button type="default" className='btn-lg disable'>Booster votre taux d’occupation</Button>
                            </div >
                        </Col >

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-ameliorer.png' className='img' alt='kliner' />
                                    <div className='blc-text'>
                                        <h4>Que pensez-vous de Kliner ?</h4>
                                        <p>Donnez-nous votre avis et des idées pour améliorer nos services.</p>
                                    </div>
                                </div>
                                <Button type="default" className='btn-lg' onClick={() => setShowModal(true)}>Aidez-nous
                                    à améliorer Kliner</Button>
                            </div>
                        </Col>
                    </Row >
                </div >

                <div className='section-3'>
                    <p className='section-title'>Astuces pour {user?.firstname}</p>
                    <Row gutter={24}>
                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <div className='img'
                                        style={{ backgroundImage: `url('assets/img/taux-occupation.jpg')` }} />
                                    <div className='blc-text'>
                                        <h4>Obtenez 100% de taux d’occupation</h4>
                                        <p>Remplissez votre planning de réservation plus rapidement grâce à notre
                                            intelligence artificielle.</p>
                                        <a href='https://help.kliner.com/fr/articles/9172447' className='btn-lire' target="_blank">LIRE</a>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <div className='img'
                                        style={{ backgroundImage: `url('assets/img/equipes-de-menage.jpg')` }} />
                                    <div className='blc-text'>
                                        <h4>Utilisez Kliner avec vos équipes de ménage</h4>
                                        <p>Intégrez vos équipes à la plateforme pour profiter de la puissance de nos outils et garder vos prix.</p>
                                        <a href='https://help.kliner.com/fr/articles/9172450'
                                            className='btn-lire' target="_blank">LIRE</a>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <div className='img'
                                        style={{ backgroundImage: `url('assets/img/frais-de-menage.jpg')` }} />
                                    <div className='blc-text'>
                                        <h4>Réduisez vos frais de ménage de 6000€!</h4>
                                        <p>Utilisez notre programme de parrainage pour faire des économies.</p>
                                        <a href='https://help.kliner.com/fr/articles/9334607'
                                            className='btn-lire' target="_blank">LIRE</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

            <Evaluation
                status={showModal}
                handleCancel={() => setShowModal(false)}
            />

            <AgrandirView
                status={showModal2}
                setValid={setValid}
                handleCancel={() => setShowModal2(false)}
            />

            <DemandeView
                status={showModalDemand}
                handleCancel={() => setShowModalDemand(false)}
            />

            <LogementCreate
                status={showModalLogementCreate}
                handleCancel={() => setShowModalLogementCreate(false)}
            />

            <ProgrammerManuellementModalView
                status={showModalProgrammerService}
                setValidProgram={() => setShowModalProgrammerValid(true)}
                handleCancel={() => setShowModalProgrammerService(false)}
            />

            <ValidProgrammationModalView
                status={showModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerValid(false)}
            />

            {showNews !== "false" && user?.isCguValidated !== null && !user?.isCguValidated && <PubModalPage
                status={showModalPub}
                user={user}
                handleCancel={() => setShowModalPub(false)}
            />}

            <LogementCreate
                status={showModalLogement}
                logementId={logementId}
                handleCancel={() => setShowModalLogement(false)}
            />
            {/* <SuspendreModalView
                status={showModalSuspendre}
                handleCancel={() => setShowModalSuspendre(false)}
            /> */}
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    );
}

export default DashBoardPage;
