import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker, Progress
} from "antd";
import {ArrowLeftOutlined, EyeFilled, MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";
import {IoArrowForwardCircleOutline} from "react-icons/io5";
import ControleFinPrestationPage from "../../controle_fin_prestation";

const ControleQualiteModalView = ({status, handleCancel, quality, prestaStatus, getPercent}) => {
    const timeFormat = 'HH:mm';
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={400}
            className="controle-qualite"
        >

            <div className="controle">
                <div className="mintitle custom-padding">
                    Contrôle qualité
                </div>
                <div className="description-controle sans-pro-regular color-A7A7A7">
                    Contrôles que nous faisons en interne <br/>
                    pour garantir la qualité de la prestation.
                </div>
                <div className="status-progress">
                    <div className="label"> {getPercent}%</div>
                    <div className="progresscontent">
                        <Progress percent={getPercent} showInfo={false} strokeColor="#74DA74"/>
                    </div>
                </div>

                <div className="bloc-status-performence">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline />
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text color-7F7F7F">
                                Présence confirmée
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img src={quality?.presence_confirmee ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"} alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bloc-status-performence">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline />
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text color-7F7F7F">
                                Linge & Kit d’accueil prêts
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img src={quality?.linge_amenities_deposes ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"} alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bloc-status-performence">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline />
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text color-7F7F7F">
                                Prestation démarrée à l’heure
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img src={quality?.prestation_started_on_time ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"} alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bloc-status-performence">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline />
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text color-7F7F7F">
                                Prestation Terminée à l’heure
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img src={quality?.prestation_finished_on_time ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"} alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="bloc-status-performence">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline />
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text color-7F7F7F">
                                Qualité de service contrôlée
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img src={quality?.bo_stars !== null  ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"} alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <br/>
            </div>

        </Modal>
    );
};
export default ControleQualiteModalView;
