import React, {useState} from "react";
import {Carousel, message, Modal, Spin} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import Slide1CGUModalPage from "./cgu-part/slide1";
import Slide2CGUModalPage from "./cgu-part/slide2";
import Slide3CGUModalPage from "./cgu-part/slide3";
import {useUserUpdateMutation} from "../../profil/services/profil-api";
import RefuserCGUModalView from "./cgu-part/refus-cgu";

const CGUModalPage = ({itemSelected, user, handleCancel}) => {
    const carouselRef = React.createRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showBarvalidation, setShowBarvalidation] = useState(false);
    const [showRefusCGU, setShowRefusCGU] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDot, setShowDot] = useState(true);
    const [userUpdate] = useUserUpdateMutation();
    const handleSubmit = () => {
        setIsLoading(true);
        userUpdate({id: user.id, data: {"isCguValidated": true}})
            .unwrap()
            .then(() => {
                handleCancel();
                setIsLoading(false);
                sessionStorage.setItem('showNews', false);
                message.success("Conditions contractuelles acceptées !");
            })
            .catch(error => {
                setIsLoading(false);
                message.error(error.data.message);
            });
    };
    const onChange = (index) => {
        setCurrentIndex(index);
    };
    console.log(currentIndex);
    return (

        <div className='CGUModalPage'>
            <div className="button-carousel-left" style={{visibility: currentIndex > 0 ? "visible" : "hidden"}}
                 onClick={() => {
                     carouselRef.current.prev();
                     if (currentIndex === 2) {
                         setShowBarvalidation(false);
                         setShowDot(true);
                     }
                 }}>
                <LeftOutlined/>
            </div>
            <div className="main-carousel">
                <Spin spinning={isLoading}>
                    <Carousel infinite={false} ref={carouselRef} afterChange={onChange} dots={showDot}>
                        <div className="caroussel-page">
                            <Slide1CGUModalPage/>
                        </div>
                        <div className="caroussel-page">
                            <Slide2CGUModalPage/>
                        </div>
                        <div className="caroussel-page">
                            <Slide3CGUModalPage user={user}/>
                        </div>
                    </Carousel>
                </Spin>
            </div>
            <div className="button-carousel-right" style={{visibility: currentIndex < 2 ? "visible" : "hidden"}}
                 onClick={() => {
                     carouselRef.current.next();
                     if (currentIndex === 1) {
                         setTimeout(() => {
                             setShowBarvalidation(true);
                         }, 300);
                         setShowDot(false);
                     }
                 }}>
                <RightOutlined/>
            </div>
            {showBarvalidation && <div className="barvalidation fade-in">
                <a href="#" className="imprimer disable">
                    Imprimer
                </a>
                <a href="#" className="accepter" onClick={() => handleSubmit()}>
                    Accepter
                </a>
                <a href="#" className="refuser" onClick={() => setShowRefusCGU(true)}>
                    Refuser
                </a>
            </div>}

            <RefuserCGUModalView
                status={showRefusCGU}
                handleCancel={setShowRefusCGU}
            />
        </div>
    );
}

export default CGUModalPage;