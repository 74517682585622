import React, { useState } from "react";
import {Badge, Button, Carousel, Col, Input, Modal, Progress, Row, Select} from "antd";


const Slide1CGUModalPage = ({itemSelected}) => {
    const carouselRef = React.createRef();

    return (
        <div className='Slide1CGUModalPage'>
            <div className="headTitle">
                <img src="/assets/img/logo.png" alt=""/>
            </div>
           <div className="content">
               <Row>
                   <Col lg={12}>
                       <div className="imageSide">
                           <img src="/assets/img/Visuel pop up 1.png" alt=""/>
                       </div>
                   </Col>
                   <Col lg={12}>
                        <div className="textSide">
                                <div className="h3">Bienvenue chez Kliner</div>
                                <div className="h2">Notre plateforme dédiée aux <span>loueurs
                                    saisonniers</span> vous permet d’automatiser
                                    l’entretien de vos locations, tout <span>en
                                    faisant des économies</span></div>
                            <div className="listElement">
                                <div className="element">
                                    <div className="image">
                                        <img src="/assets/img/Picto pop up - 1.png" alt=""/>
                                    </div>
                                    <div className="text">
                                        Profitez des prestations les moins chères du marché : <br/>
                                        ménage, linge et kit d’accueil.
                                    </div>
                                </div>
                                <div className="element">
                                    <div className="image">
                                        <img src="/assets/img/Picto pop up - 2.png" alt=""/>
                                    </div>
                                    <div className="text">
                                        Devenez ou restez Superhôte grâce à un service de ménage
                                        de qualité hôtelière.
                                    </div>
                                </div>
                            </div>

                        </div>
                   </Col>
               </Row>
           </div>
        </div>
    );
}

export default Slide1CGUModalPage;