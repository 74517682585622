import React, {useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Row, Col, Switch, Divider, message, Spin, Popover} from "antd";
import GestionCompleteView from "./gestion-du-linge/gestion-complete-view";
import VousFournissezView from "./gestion-du-linge/vous-fournissez-view";
import {
    useCoutDureeUpdateMutation,
    useLogementUpdateMutation,
    useLogementWithPerformanceMutation
} from '../services/logement-api';
import LingeSaleView from './gestion-du-linge/linge-sale';
import {setRefresh} from '../services/logement-slice';


function GestionLingeView({logement}) {
    const [messageApi, contextHolder] = message.useMessage();
    const [logementUpdate] = useLogementUpdateMutation();
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const [fullyManaged, setFullyManaged] = useState(logement.laundryManagementType === "FULLY_MANAGED");
    const [partiallyManaged, setPartiallyManaged] = useState(logement.laundryManagementType === "PARTIALLY_MANAGED");
    const [self, setSelf] = useState(logement.laundryManagementType === "SELF");
    const [longTime, setLongTime] = useState(false);
    const [averageLeaseDuration, setAverageLeaseDuration] = useState(false);
    const [lit, setLit] = useState({});
    const [plan, setPlan] = useState();
    const [douche, setDouche] = useState();
    const {user} = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false)
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const dispatch = useDispatch();

    const onChange = (checked, mode) => {
        setSelf(false);
        setFullyManaged(false);
        setPartiallyManaged(false);
        setLongTime(false);
        if (mode === "FULLY_MANAGED") {
            setFullyManaged(checked);
            handleManagementType(checked ? "FULLY_MANAGED" : "None")
        }

        if (mode === "SELF") {
            setSelf(checked);
            handleManagementType(checked ? "SELF" : "None")
        }

        if (mode === "PARTIALLY_MANAGED") {
            setPartiallyManaged(checked);
            handleManagementType(checked ? "PARTIALLY_MANAGED" : "None")
        }

        if (mode === "LONG_TIME") {
            setLongTime(checked);
            handleManagementType(checked ? "LONG_TIME" : "None")
        }
    };

    const handleChangeAverage = (checked) => {
        setAverageLeaseDuration(checked)
    }

    const handleManagementType = (managementType) => {
        const data = ({
            logementId: logement.id,
            laundryManagementType: managementType
        })
        // FULLY_MANAGED
        logementUpdate({id: logement.id, data: data}).unwrap()
            .then(() => {
                if (managementType !== "FULLY_MANAGED") {
                    handleUpdateCout(managementType)
                } else {
                    setLoading(false)
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                }
            })
            .catch(() => setLoading(false));
    };


    const handleUpdateCout = (managementType) => {
        const data = ({
            type: "gestion_lit",
            logementId: logement.id,
            userId: user.id,
            laundry: {
                type: "kit",
                management_type: managementType,
                average_lease_duration: averageLeaseDuration
            },
            nbre_lit: lit === "" ? 0 : (parseInt(lit.unePlace) + parseInt(lit.deuxPlace) + parseInt(lit.kingSize)),
        })
        coutDureeUpdate(data).unwrap()
            .then(() => {
                setLoading(false)
                handleFetchLogements();
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success',
                    duration: 0.5
                });
            })
            .catch(() => setLoading(false));
    };


    const handleFetchLogements = async () => {
        const data = ({page: 1, pageSize: 10})
        logementWithPerformance(data).unwrap()
            .then((res) => {
            })
            .catch(() => {
            });
    };


    const contentInfo = (
        <div className="infoLinge">
            <p>
                Votre linge sale est récupéré par l’équipe de ménage. <br/>
                Il est lavé et vous est retourné lors de la prochaine <br/>
                prestation dans le logement.
            </p>
        </div>
    );

    return (
        <div className="blc-right gestion-linge">
            <Spin spinning={loading}>{contextHolder}
                <div className="title">Gestion du linge</div>
                <Divider/>
                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="subTitle">Linge de lit et de bain</div>
                        <p className="calibri-light color-566F8F" style={{display: "inline-block"}}>Offrir le meilleur
                            confort à vos voyageurs</p>
                        {fullyManaged &&
                            <div className="longue-duree">
                                <Switch value={averageLeaseDuration}
                                        onChange={(checked) => handleChangeAverage(checked)}/>
                                <span className="calibri-light color-566F8F">Location moyenne durée</span>
                                <Popover placement="top" content={contentInfo} overlayClassName="infoContentLinge">
                                    <div className="info-linge">
                                        <img src="/assets/img/info-circled.png" alt="info"/>
                                    </div>
                                </Popover>
                            </div>}
                        <div className="blc-content">
                            <Row gutter={24}>
                                <Col lg={24}>
                                    <Row gutter={24}>
                                        <Col lg={12}>
                                            <p className="mintitle">Quel linge propre sera mis à disposition des
                                                voyageurs et que devrons-nous faire du linge sale ?</p>
                                        </Col>
                                    </Row>
                                    <br/>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={17}>
                                                <p className="mintitle color-213856 sans-pro-semi-bold">Gestion complète
                                                    du linge par Kliner <a
                                                        href="https://help.kliner.com/fr/articles/8992948-comment-fonctionne-la-location-de-linge-de-maison-chez-kliner"
                                                        target="_blank">(En savoir plus)</a></p>
                                                <span className="color-5B6E8C sans-pro-light">Kliner fournit le linge propre  à chaque prestation en fonction du nombre et du type de lits dans le logement</span>
                                            </Col>

                                            <Col lg={2}></Col>
                                            <Col lg={2} className="switch-side first">
                                                <Switch value={fullyManaged}
                                                        onChange={(checked) => onChange(checked, "FULLY_MANAGED")}/>
                                            </Col>
                                        </Row>
                                        <Divider/>
                                    </div>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={17}>
                                                <p className="mintitle color-213856 sans-pro-semi-bold">Vous fournissez
                                                    le linge propre et gérez vous-même le linge sale</p>
                                                <span className="color-5B6E8C sans-pro-light">L’aide-ménagère dresse les lits avec le linge que vous mettez à disposition et dépose le linge sale dans le panier indiqué</span>
                                            </Col>

                                            <Col lg={2}></Col>
                                            <Col lg={2} className="switch-side">
                                                <Switch value={self} onChange={(checked) => onChange(checked, "SELF")}/>
                                            </Col>
                                        </Row>
                                        <Divider/>
                                    </div>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={17} className="pRelative">
                                                <p className="mintitle color-213856 sans-pro-semi-bold">Vous fournissez le linge propre et votre linge sale est lavé par Kliner
                                                    <Popover placement="top" content={contentInfo}
                                                             overlayClassName="infoContentLinge">
                                                        <div className="info-linge">
                                                            <img src="/assets/img/info-circled.png" alt="info"/>
                                                        </div>
                                                    </Popover>
                                                </p>
                                                <span className="color-5B6E8C sans-pro-light">L’aide-ménagère dresse les lits avec le linge que vous mettez à disposition et s’occupe de laver votre linge sale dans un Lavomatic à proximité</span>
                                            </Col>
                                            <Col lg={2}></Col>
                                            <Col lg={2} className="switch-side">
                                                <Switch value={partiallyManaged}
                                                        onChange={(checked) => onChange(checked, "PARTIALLY_MANAGED")}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br/><br/>
                        </div>
                            <br/>
                            <br/>
                        {fullyManaged && <>
                            <div className="subTitle"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb"
                                                           className="title-img"/>Caution linge
                            </div>
                            <div className="blc-content">
                                <Row gutter={24}>
                                    <Col lg={20}>
                                        <p>
                                            Si vous nous confiez la fourniture du linge, au terme de la première
                                            prestation de
                                            ménage nous prélèverons une caution de 60€ par lit en gestion. Cette caution
                                            est
                                            automatiquement remboursée sous 30 jours si vous arrêtez les prestations de
                                            ménage sur
                                            le logement.
                                            <a href="https://help.kliner.com/fr/articles/9813220" target="_blank">En
                                                savoir plus</a>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        }
                    </Col>
                    <Col lg={10}>
                        {fullyManaged &&
                            <GestionCompleteView
                                lit={setLit}
                                douche={setDouche}
                                plan={setPlan}
                                logement={logement}
                                averageLeaseDuration={averageLeaseDuration}
                            />}
                        {partiallyManaged && <LingeSaleView lit={setLit} douche={setDouche} logement={logement}/>}
                        {self && <VousFournissezView lit={setLit} douche={setDouche} logement={logement}/>}
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default GestionLingeView;