import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin} from "antd";
import {useNavigate} from "react-router-dom";

const RefuserCGUModalView = ({ status, handleCancel }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const handleLogout = () => {
        setIsLoading(true);
        handleCancel();
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={700}
            className="suppressionLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/warning-icon.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Êtes-vous sûr de vouloir refuser les conditions contractuelles ?</div>
                    </div>
                    <Row>
                        <Col>
                            <span>
                                Le refus des Conditions contractuelles peut potentiellement entrainer une suppression de votre compte.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Non, j'accepte les conditions</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => handleLogout()}
                                   className="suivant sans-pro-regular">Oui, je ne suis pas d'accord!</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default RefuserCGUModalView;
