import React, { useState, useEffect } from 'react';
import {Button, Col, Divider, Popover, Row, Switch, message, Spin} from "antd";
import "../styles/style.scss"
import { useGetBillingInfoQuery, useUpdateBillingInfoMutation } from '../services/profil-api';


function DelaiPartPage() {
    const [debitFin, setDebitFin] = useState(false);
    const [debitAtteinte, setDebitAtteinte] = useState(false);
    const [j7, setJ7] = useState(false);
    const [j15, setJ15] = useState(false);
    const [j20, setJ20] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [autoDebit, setAutoDebit] = useState(null);
    const [billingData, setBillingData] = useState(null);
    const billingInfo = useGetBillingInfoQuery();
    const [updateBillingInfo] = useUpdateBillingInfoMutation();

    useEffect(() => {
        if(!billingData) {
            setBillingData(billingInfo?.data);
            setAutoDebit(billingInfo?.data?.charge_automatically);
            console.log("=============================",billingData)
        }
    }, [billingData, billingInfo]);



    const contentInfo = (
        <div className="infoLogement">
            <p>
                Le choix du ‘Paiement manuel’ n’est <br/>
                possible qu’à partir du moment où <br/>
                votre compte aura comptabilisé <br/>
                5 factures payées.
            </p>
        </div>
    );

    const updateData = (data)=> {
        setLoading(true)
        updateBillingInfo(data)
            .unwrap()
            .then((res) => {
                if (res.status === 400) {
                    setLoading(false);
                    message.error(res.message);
                    setError(res.message);
                } else {
                    setLoading(false)
                    message.success("Mise à jour effectuée avec succès!");
                }
            })
            .catch((error) => {
                setLoading(false);
                message.error(error?.message || "Echec de mise à jour!");
                setError(error?.message || "Echec de mise à jour!");
                console.log("error:", error);
            });
    }

    const setDebitMode = (action, autoDebit, billingEvent, dueDays)=> {
        if(action === false || !billingData)
            return
        console.log({action, autoDebit, billingEvent, dueDays})
        const data = {...billingData}
        if(autoDebit && !action) {
            setAutoDebit(true);
            if(billingEvent === "prestation_end") {
                data.charge_automatically = true;
                data.charge_automatically_event = "threshold_attained";
                data.manual_debit_due_days = 0;
            } else {
                data.charge_automatically = true;
                data.charge_automatically_event = "prestation_end";
                data.manual_debit_due_days = 0;
            }
        } else if(!autoDebit && !action) {
            setAutoDebit(true);
            data.charge_automatically = true;
            data.charge_automatically_event = "prestation_end";
            data.manual_debit_due_days = 0;
        } else {
            setAutoDebit(autoDebit);
            if(autoDebit && billingEvent) {
                data.charge_automatically = true;
                data.charge_automatically_event = billingEvent;
                data.manual_debit_due_days = 0;
            } else {
                data.charge_automatically = false;
                data.charge_automatically_event = "threshold_attained";
                data.manual_debit_due_days = dueDays;
            }
        }
        setBillingData(data)
        updateData(data);
        return
    }

    return (
        <Spin spinning={loading}>
            <div className="profil-part-page">
                <Row gutter={24}>
                    <Col lg={18}>
                      <h2 className="sans-pro-semi-bold">Délais de paiement</h2>
                    </Col >

{/*                     <Col lg={4}>
                            <Button
                                type="primary"
                                className="btn-lg"
                                onClick={updateData}
                            >
                                    Enregistrer
                            </Button>
                    </Col> */}
                </Row>
                <div className="headprofil">
                    <div className="head sans-pro-semi-bold">
                        Quel délai et mode de paiement vous conviennent ?
                        <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                            <div style={{width:"13px", display:"inline-block", marginLeft:"10px", marginTop:"1px", verticalAlign:"top"}}>
                                <img src="/assets/img/info-circled.png" style={{width:"100%"}} alt="info"/>
                            </div>
                        </Popover>
                    </div>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={17}>
                            <p className="mintitle color-black sans-pro-semi-bold" style={{marginBottom:"4px"}}>Débit à la fin de chaque prestation</p>
                            <span className="color-5B6E8C sans-pro-light">Kliner émet une facture à la fin de chaque prestation et débite automatiquement votre moyen de paiement enregistré.</span>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={6} className="switch-side first text-align-center">
                            <br/>
                            <Switch
                                    onChange={(checked) => setDebitMode(checked, true, "prestation_end",0)}
                                    value={autoDebit && billingData?.charge_automatically && billingData?.charge_automatically_event === "prestation_end"}
                                    disabled={autoDebit && billingData?.charge_automatically && billingData?.charge_automatically_event === "prestation_end"}
                                />
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={17}>
                            <p className="mintitle color-black sans-pro-semi-bold" style={{marginBottom:"4px"}}>Débit dès l’atteinte du seuil de consommation</p>
                            <span className="color-5B6E8C sans-pro-light">Kliner émet une facture à la fin du mois ou dès que votre consommation atteint le seuil de {billingData?.billing_thresholds.enterprise}€ pour les entreprises et {billingData?.billing_thresholds.particular}€ pour les particuliers. Kliner débite automatiquement votre moyen de paiement enregistré à l’émission de la facture.</span>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={6} className="switch-side first text-align-center">
                            <br/>
                            <br/>
                            <Switch
                                    onChange={(checked) => setDebitMode(checked, true, "threshold_attained",0)}
                                    value={autoDebit && billingData?.charge_automatically && billingData?.charge_automatically_event === "threshold_attained"}
                                    disabled={autoDebit && billingData?.charge_automatically && billingData?.charge_automatically_event === "threshold_attained"}
                             />
                        </Col>
                    </Row>
                    <br/>
                    <Divider/>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={17}>
                            <p className="mintitle color-black sans-pro-semi-bold" style={{marginBottom:"4px"}}>Paiement manuel à J+7</p>
                            <span className="color-5B6E8C sans-pro-light">Kliner vous envoie la facture afin que vous procédiez vous-même au règlement du total dû par CB, prélèvement SEPA ou virement bancaire, dans un délai maximum de 7 jours à compter de la réception.</span>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={6} className="switch-side first text-align-center">
                            <br/>
                            <br/>
                            <Switch
                                    onChange={(checked) => setDebitMode(checked, false, "threshold_attained",7)}
                                    disabled={(billingData?.paidInvoiceCount < 5) || (autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 7)}
                                    value={autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 7}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={17}>
                            <p className="mintitle color-black sans-pro-semi-bold" style={{marginBottom:"4px"}}>Paiement manuel à J+15</p>
                            <span className="color-5B6E8C sans-pro-light">Kliner vous envoie la facture afin que vous procédiez vous-même au règlement du total dû par CB, prélèvement SEPA ou virement bancaire, dans un délai maximum de 15 jours à compter de la réception.</span>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={6} className="switch-side first text-align-center">
                            <br/>
                            <br/>
                            <Switch
                                    onChange={(checked) => setDebitMode(checked, false, "threshold_attained",15)}
                                    disabled={(billingData?.paidInvoiceCount < 5) || (autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 15)}
                                    value={autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 15}/>
                            <p className="color-5B6E8C" style={{fontSize:"13px"}}>
                                <em>Entraîne une majoration de +1% du total de la facture</em>
                            </p>
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={17}>
                            <p className="mintitle color-black sans-pro-semi-bold" style={{marginBottom:"4px"}}>Paiement manuel à J+20</p>
                            <span className="color-5B6E8C sans-pro-light">Kliner vous envoie la facture afin que vous procédiez vous-même au règlement du total dû par CB, prélèvement SEPA ou virement bancaire, dans un délai maximum de 20 jours à compter de la réception.</span>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={6} className="switch-side first text-align-center">
                            <br/>
                            <br/>
                            <Switch
                                    onChange={(checked) => setDebitMode(checked, false, "threshold_attained",20)}
                                    disabled={(billingData?.paidInvoiceCount < 5) || (autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 20)}
                                    value={autoDebit=== false && billingData?.charge_automatically === false && billingData?.manual_debit_due_days === 20}/>
                            <p className="color-5B6E8C" style={{fontSize:"13px"}}>
                                <em>Entraîne une majoration de +2% du total de la facture</em>
                            </p>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Row gutter={24}>
                        <Col lg={2}/>
                        <Col lg={20}>
                            <p className="text-align-center color-595959 sans-pro-semi-bold" style={{fontSize:"13px"}}>
                                <em>    * Les factures pour paiement manuel sont envoyées à la fin du mois ou dès lors que votre consommation en cours de mois atteint le seuil de 4 000€ pour les particuliers et 10 000€ pour les entreprises.</em>
                            </p>
                        </Col>
                        <Col lg={2}/>
                    </Row>
                </div>
            </div>
        </Spin>
    );
}

export default DelaiPartPage;
