import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Divider, Input, message, Spin } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useCoutDureeUpdateMutation, useLogementUpdateMutation } from '../../services/logement-api';
import { setRefresh } from '../../services/logement-slice';

function GestionCompleteView({ lit, douche, plan, logement, averageLeaseDuration }) {
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useSelector((state) => state.auth);
    const [planSelected, setPlanSelected] = useState(logement.laundryPlan);
    const [litUnePlace, setLitUnePlace] = useState(logement.lit_simple == null ? 0 : parseInt(logement.lit_simple));
    const [litDeuxPlace, setLitDeuxPlace] = useState(logement.lit_double == null ? 0 : parseInt(logement.lit_double));
    const [litKQSize, setLitKQSize] = useState(logement.lit_king == null ? 0 : parseInt(logement.lit_king));
    const [doucheSdbain, setDoucheSdbain] = useState(logement.douche == null  ? 1 : parseInt(logement.douche) === 0 ? 1 : parseInt(logement.douche));
    const [logementUpdate] = useLogementUpdateMutation();
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const [loading, setLoading] = useState(false);
    const [loadingPlan, setLoadingPlan] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lit) {
            lit({
                unePlace: litUnePlace,
                deuxPlace: litDeuxPlace,
                kingSize: litKQSize,
            });
        }
    }, [lit, litUnePlace, litDeuxPlace, litKQSize]);

    useEffect(() => {
        if (douche) {
            douche(doucheSdbain);
        }
    }, [douche, doucheSdbain]);

    const handleChangePlan = (value) => {
        setLoadingPlan(true)
        setPlanSelected(value);
        plan(planSelected);
        handleUpdate({ laundryPlan: value })
    };

    useEffect(() => {
        if (douche) {
            douche(doucheSdbain);
        }
    }, [douche, doucheSdbain]);


    const changeNbMoins = (value) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(litUnePlace - 1);
            handleUpdate({ lit_simple: parseInt(litUnePlace) - 1 })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(litDeuxPlace - 1);
            handleUpdate({ lit_double: parseInt(litDeuxPlace) - 1 })
        }
        if (value === "litKQSize") {
            setLitKQSize(litKQSize - 1);
            handleUpdate({ lit_king: parseInt(litKQSize) - 1 })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(doucheSdbain - 1);
            handleUpdate({ douche: parseInt(doucheSdbain) - 1 })
        }
    };

    const handleChange = (e, value) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(e);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(e);
        }
    };

    const changeNbPlus = (value, nb) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(nb);
            handleUpdate({ lit_simple: nb })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(parseInt(litDeuxPlace) + 1);
            handleUpdate({ lit_double: parseInt(litDeuxPlace) + 1 })
        }
        if (value === "litKQSize") {
            setLitKQSize(parseInt(litKQSize) + 1);
            handleUpdate({ lit_king: parseInt(litKQSize) + 1 })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(parseInt(doucheSdbain) + 1);
            handleUpdate({ douche: parseInt(doucheSdbain) + 1 })
        }
    };

    const handleUpdate = (data) => {
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => { })
            .catch(() => { });
        setLoading(false)
    };

    const handleBlur = (e, value) => {
        setLoading(true)
        if (value === "litUnePlace") {
            setLitUnePlace(e);
            handleUpdate({ lit_simple: parseInt(e) })
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
            handleUpdate({ lit_double: parseInt(e) })
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
            handleUpdate({ lit_king: parseInt(e) })
        }
        if (value === "doucheSdbain") {
            setDoucheSdbain(e);
            handleUpdate({ douche: parseInt(e) })
        }
    };


    const handleUpdateCout = () => {
        setLoading(false)
        const data = ({
            type: "gestion_lit",
            logementId: logement.id,
            userId: user.id,
            laundry: {
                type: "kit",
                management_type: "FULLY_MANAGED",
                plan: planSelected,
                average_lease_duration: averageLeaseDuration,
            },
            nbre_lit: parseInt(litUnePlace) + parseInt(litDeuxPlace) + parseInt(litKQSize),
        })
        coutDureeUpdate(data).unwrap()
            .then(() => {
                setLoading(false)
                setLoadingPlan(false)
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success',
                    duration: 0.5
                });
            })
            .catch(() => { });
    };

    useEffect(() => {
        handleUpdateCout()
    }, [litUnePlace, litDeuxPlace, litKQSize, planSelected, averageLeaseDuration]);

    return (
        <div className="gestion-complete blc-right">
            {contextHolder}
            <div className="subTitle">Lits & douches dans le logement * (obligatoire) </div>
            <Spin spinning={loading}>
                <Row gutter={24}>
                    <div className="blc-content">
                        <br />
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits une (1) place </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litUnePlace === 0 ? "disable-Button" : "")}
                                                onClick={litUnePlace === 0 ? null : () => changeNbMoins("litUnePlace")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input-alter" value={litUnePlace}
                                                    onChange={(e) => handleChange(e.target.value, "litUnePlace")}
                                                    onBlur={(e) => handleBlur(e.target.value, "litUnePlace")} />
                                            </div>
                                            <span className="plus"
                                                onClick={() => changeNbPlus("litUnePlace", (parseInt(litUnePlace) + 1))}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits deux (2) places </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litDeuxPlace === 0 ? "disable-Button" : "")}
                                                onClick={litDeuxPlace === 0 ? null : () => changeNbMoins("litDeuxPlace")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input" value={litDeuxPlace}
                                                    onChange={(e) => handleChange(e.target.value, "litDeuxPlace")}
                                                    onBlur={(e) => handleBlur(e.target.value, "litDeuxPlace")} />
                                            </div>
                                            <span className="plus"
                                                onClick={() => changeNbPlus("litDeuxPlace")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de lits KING ou QUEEN size </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (litKQSize === 0 ? "disable-Button" : "")}
                                                onClick={litKQSize === 0 ? null : () => changeNbMoins("litKQSize")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input-alter" value={litKQSize}
                                                    onChange={(e) => handleChange(e.target.value, "litKQSize")}
                                                    onBlur={(e) => handleBlur(e.target.value, "litKQSize")} />
                                            </div>
                                            <span className="plus"
                                                onClick={() => changeNbPlus("litKQSize")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Divider />
                            <Col lg={24}>
                                <div className="contour-point-gris">
                                    <Row gutter={24}>
                                        <Col lg={17}>
                                            <p>Nombre de douche ou salle de bain </p>
                                        </Col>
                                        <Col>
                                            <span className={"moins " + (doucheSdbain === 1 ? "disable-Button" : "")}
                                                onClick={doucheSdbain === 1 ? null : () => changeNbMoins("doucheSdbain")}> <MinusOutlined /> </span>
                                            <div className="nombre4">
                                                <Input className="nombre-input" value={doucheSdbain}
                                                    onChange={(e) => handleChange(e.target.value, "doucheSdbain")}
                                                    onBlur={(e) => handleBlur(e.target.value, "doucheSdbain")} />
                                            </div>
                                            <span className="plus"
                                                onClick={() => changeNbPlus("doucheSdbain")}> <PlusOutlined />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </div>
                    <br />
                </Row >
            </Spin >
            <div className="subTitle">Qualité du linge</div>
            <Spin spinning={loadingPlan}>
                <Row gutter={24}>
                    <div className="blc-content">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <Row>
                                    <Col lg={6} className="qualite-linge-choix ">
                                        <div className={"qualite-linge-image-zone " + (planSelected === "standard" ? 'qualite-linge-image-zone-selected' : '')}
                                            onClick={() => handleChangePlan("standard")}>
                                            <div className="checkButton">
                                                <img src="./assets/img/green-check.png" alt="check" />
                                            </div>
                                            <img src="./assets/img/standard.png" className="qualite-linge-image" alt="standard" />
                                        </div>
                                        <p className={"qualite-linge-label " + (planSelected === "standard" ? ' sans-pro-semi-bold' : ' sans-pro-regular color-5B6E8C')}>Standard</p>
                                    </Col>
                                    <Col lg={1}></Col>
                                    <Col lg={6} className="qualite-linge-choix">
                                        <div className={"qualite-linge-image-zone " + (planSelected === "luxury" ? 'qualite-linge-image-zone-selected' : '')} onClick={() => handleChangePlan("luxury")}>
                                            <div className="checkButton">
                                                <img src="./assets/img/green-check.png" alt="check" />
                                            </div>
                                            <img src="./assets/img/luxe.png" className="qualite-linge-image" alt="luxe" />
                                        </div>
                                        <p className={"qualite-linge-label " + (planSelected === "luxury" ? ' sans-pro-semi-bold' : ' sans-pro-regular color-5B6E8C')}>Premium</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={24}>
                                <br className="ifNormal"/><br />
                                <p className="qualite-linge-description sans-pro-semi-bold"><strong>Description</strong></p>
                                <p className="color-5B6E8C sans-pro-light">
                                    {planSelected === "standard" && <span>Linge d’hôtellerie éco-responsable récupéré des hôtels quatre et cinq étoiles et triés avec grand soin pour leur offrir un nouveau cycle de vie sans rogner sur la qualité.</span>

                                    }
                                    {planSelected === "luxury" &&
                                        <span>
                                            Draps, taies et housses de couette de qualité supérieure 100% coton,
                                            Percale 83 fils/cm2. Serviettes de bain et draps de douche 20/2 fil
                                            d’anneau, 600gr/m2.
                                        </span>
                                    }
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Spin>
        </div >
    );
}

export default GestionCompleteView;